import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
/** Material UI Components */
import {
  Table as MuiTable,
  Paper as MuiPaper,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@mui/material";
import OutcomesDetailsTableHeader from "./OutcomesDetailsTableHeader";
import OutcomesDetailsTableBody from "./OutcomesDetailsTableBody";
import { TableWithPaging } from "app/shared/ui/Layout/TableWithPaging/TableWithPaging";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
/**  Services */
import {
  getAllOutcomesList,
  removeAddById,
} from "app/services/outcomesService";
import { useOutcomesContext } from "app/services/outcomesContext";
import { useOutcomesSelectedContext } from "app/services/outcomesSelectedContext";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { outcomeDetailsStyles } from "./OutcomesDetails.styles";

// ************ Display Outcomes Details Table ****************
export const OutcomesDetails = (props, ref) => {
  const { t } = useTranslation();
  const { handleSelectionChange, ignoreSelection } =
    useOutcomesSelectedContext();
  const { outcomesPreferences, setOutcomesPreferences } = useOutcomesContext();
  const outcomesMainViewMessages = "outcomes.mainView";

  // ********** State Values ********
  const order = useMemo(
    () => outcomesPreferences.values.sort.order || "asc",
    [outcomesPreferences]
  );
  const orderBy = useMemo(
    () => outcomesPreferences.values.sort.orderBy || "trainee",
    [outcomesPreferences]
  );
  const { setMetaData } = useSelectedSubmission();
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [outcomesList, setOutcomesList] = useState([]);
  const [, setError] = useState(null);
  const subId = getSelectedSubmission();
  const { navigate, invitationsSent, setSummary, loading, setLoadingItems } =
    props;
  const { setAlert, clearAlert } = useAlerts();
  const [actionType, setActionType] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [confirmation, setConfirmation] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOutcomesPreferences({
      ...outcomesPreferences,
      values: {
        ...outcomesPreferences.values,
        sort: {
          order: isAsc,
          orderBy: property,
        },
      },
    });
  };

  const toggleActive = (rowId, row, type) => {
    setActionType(type);

    if (row.isActive) {
      ignoreSelection(row);
    } else {
      handleSelectionChange({ target: { checked: false } }, row);
    }

    setSnackbar(false);

    removeAddById(
      getSelectedSubmission(),
      rowId,
      row,
      setSnackbar,
      setAlert,
      clearAlert,
      setLoadingItems,
      outcomesPreferences,
      setOutcomesPreferences
    );
  };

  const handleRemove = (action) => {
    toggleActive(confirmation.rowId, confirmation.row, confirmation.type);
    setConfirmation({
      ...confirmation,
      confirm: false,
    });
  };

  const handleConfirmation = (rowId, row, type) => {
    setConfirmation({
      rowId: rowId,
      row: row,
      type: type,
      confirm: true,
    });
  };

  const showConfirmationSnackBar = () =>
    snackbar ? (
      <ShowSnackBar
        message={
          actionType === "delete"
            ? t(`${outcomesMainViewMessages}.removeOutcomeMessage`)
            : t(`${outcomesMainViewMessages}.addOutcomeMessage`)
        }
      />
    ) : null;

  const showDialogRemoveConfirmation = () =>
    confirmation.confirm ? (
      <ConfirmationDialog
        handleCancel={() =>
          setConfirmation({
            ...confirmation,
            confirm: false,
          })
        }
        handleOk={() => handleRemove()}
        open={true}
        title={t(`${outcomesMainViewMessages}.delete.confirmationBox.title`)}
        message={t(`${outcomesMainViewMessages}.delete.confirmationBox.body`, {
          name: confirmation.row.traineeName,
          count: "",
          plural: "",
        })}
        okLabel={t(
          `${outcomesMainViewMessages}.delete.confirmationBox.okLabel`
        )}
        cancelLabel={t(
          `${outcomesMainViewMessages}.delete.confirmationBox.cancelLabel`
        )}
      />
    ) : null;

  const stringifiedOutcomesPreferences = JSON.stringify(outcomesPreferences);

  useEffect(() => {
    getAllOutcomesList(
      setOutcomesList,
      setSummary,
      subId,
      setLoadingItems,
      setAlert,
      clearAlert,
      setError,
      outcomesPreferences,
      navigate,
      setMetaData,
      getSelectedSubmissionName,
      t
    );

    //eslint-disable-next-line
  }, [
    order,
    orderBy,
    //    subId,
    stringifiedOutcomesPreferences,
    navigate,
    setAlert,
    clearAlert,
    invitationsSent,
  ]);

  return (
    <>
      {showConfirmationSnackBar()}
      {showDialogRemoveConfirmation()}

      <TableWithPaging
        component={MuiPaper}
        sx={outcomeDetailsStyles.tableContainerRoot}
        context={{
          preferences: outcomesPreferences,
          setPreferences: setOutcomesPreferences,
        }}
        values={outcomesList}
      >
        <MuiTable
          aria-label="customized table"
          stickyHeader
          sx={{
            tableLayout: "fixed",
            "& .MuiTableCell-root": {
              wordWrap: "break-word",
            },
          }}
        >
          <OutcomesDetailsTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            hasAddOutcomeAccess={props.hasAddOutcomeAccess}
          />
          {loading ? (
            <MuiTableBody>
              <MuiTableRow>
                <MuiTableCell colSpan="11">
                  <MuiTypography variant="body2" color="inherit" align="center">
                    {t("globals.list.messages.fetchingInfo")}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          ) : (
            <OutcomesDetailsTableBody
              outcomesList={outcomesList}
              handleConfirmation={handleConfirmation}
              toggleActive={toggleActive}
            />
          )}
        </MuiTable>
        {loading && (
          <MuiBox sx={{ width: "100%" }}>
            <MuiLinearProgress />
          </MuiBox>
        )}
      </TableWithPaging>
    </>
  );
};
