import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid2 as MuiGrid,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { NavigateNext as MuiNavigateNextIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
/** Custom Components */
import { FormButton } from "app/shared/ui/Button/FormButton";
import { Spinner } from "app/shared/ui/Loading/Spinner";
import { submissionType, monthOptions } from "app/shared/constants";
import { buildYearRange } from "app/shared/utils";
import { Autocomplete } from "app/shared/ui/Autocomplete/Autocomplete";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
/** Services */
import { usePeopleAPI } from "app/services/usePeopleAPI";
import { useSubmissionsAPI } from "app/services/useSubmissionsAPI";
import { sharedStyles } from "app/shared/ui/sharedStyles";

// TODO: Rename to SubmissionForm
export const CreateSubmission = (props) => {
  const navigate = useNavigate();
  const submissionId = useParams().submissionId;
  const { loadSubmissionValues, saveSubmissionValues, isLoading, isSaving } =
    useSubmissionsAPI();
  const { t } = useTranslation();
  const yearOptions = buildYearRange(5, 5);

  const [submissionValues, setSubmissionValues] = useState({
    name: "",
    year: "",
    month: "",
    admins: [],
    observers: [],
    grant: null,
  });

  useEffect(() => {
    if (submissionId) {
      loadSubmissionValues(submissionId, setSubmissionValues);
    }
  }, [submissionId, loadSubmissionValues]);

  const formik = useFormik({
    initialValues: submissionValues,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required(
          t("submission.create.form.fields.submissionName.validation.isEmpty")
        ),
      year: yup
        .string()
        .required(
          t("submission.create.form.fields.submissionYear.validation.isEmpty")
        ),
      month: yup
        .string()
        .required(
          t("submission.create.form.fields.submissionMonth.validation.isEmpty")
        ),
    }),
    onSubmit: (values, { setFieldError }) => {
      saveSubmissionValues(props.type, submissionId, values, setFieldError);
    },
  });

  const handleCancel = () => {
    navigate("/admin/submissions");
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      justify="flex-start"
      direction="column"
      spacing={2}
    >
      <Spinner visible={isLoading || isSaving} />
      <MuiGrid>
        <MuiBreadcrumbs
          separator={<MuiNavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={sharedStyles.breadcrumb}
        >
          <RouteLink to="/admin/submissions">
            {t("submission.manage.title")}
          </RouteLink>
          <MuiTypography color="textPrimary">
            {props.type === submissionType.create
              ? t("submission.create.title")
              : t("submission.edit.title")}
          </MuiTypography>
        </MuiBreadcrumbs>
      </MuiGrid>
      <MuiGrid>
        <MuiTypography variant="h5">
          {props.type === submissionType.create
            ? t("submission.create.title")
            : t("submission.edit.title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <form onSubmit={formik.handleSubmit}>
          <MuiGrid
            container
            direction="column"
            justify="space-between"
            spacing={2}
            size={4}
          >
            <MuiGrid>
              <Autocomplete
                id="grantAutoComplete"
                key="submission-grant"
                size="medium"
                getOptionLabel={(option) =>
                  option &&
                  (option?.displayText ??
                    [
                      option?.spoNumber,
                      option?.grantNumber,
                      option?.title,
                    ].join(", "))
                }
                value={formik.values.grant}
                label={t(`submission.create.form.fields.grant.label`)}
                noOptionsText={t(
                  `submission.create.form.fields.grant.noOptionText`
                )}
                onChange={(e, value) => {
                  formik.setFieldValue("grant", value);
                  if (!formik?.values?.name) {
                    formik.setFieldValue("name", value?.value?.title ?? "");
                  }
                }}
                disabled={
                  props.type === submissionType.edit &&
                  Boolean(formik.initialValues.grant)
                }
                autoCompleteUrlPrefix={`/grants/autocomplete?`}
                renderOption={(props, state, { inputValue }) => (
                  <AutocompleteOption
                    option={props}
                    inputValue={inputValue}
                    state={state}
                  />
                )}
              />
            </MuiGrid>
            <MuiGrid>
              <MuiTextField
                name="name"
                label={t("submission.create.form.fields.submissionName.label")}
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{ maxLength: 200 }}
                autoComplete="off"
                variant="outlined"
                fullWidth
              />
            </MuiGrid>
            <MuiGrid container>
              <MuiGrid size={6}>
                <MuiTextField
                  name="year"
                  select
                  label={t(
                    "submission.create.form.fields.submissionYear.label"
                  )}
                  required
                  value={formik.values.year}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && Boolean(formik.errors.year)}
                  helperText={formik.touched.year && formik.errors.year}
                  variant="outlined"
                  disabled={props.type === submissionType.edit}
                  fullWidth
                >
                  {yearOptions.map((option) => (
                    <MuiMenuItem key={option} value={option}>
                      {option}
                    </MuiMenuItem>
                  ))}
                </MuiTextField>
              </MuiGrid>
              <MuiGrid size={6}>
                <MuiTextField
                  name="month"
                  select
                  label={t(
                    "submission.create.form.fields.submissionMonth.label"
                  )}
                  required
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && Boolean(formik.errors.month)}
                  helperText={formik.touched.month && formik.errors.month}
                  variant="outlined"
                  disabled={props.type === submissionType.edit}
                  fullWidth
                >
                  {monthOptions.map((option) => {
                    const submissionMonth =
                      option.toString().length < 2 ? `0${option}` : option;
                    return (
                      <MuiMenuItem key={option} value={option}>
                        {submissionMonth}
                      </MuiMenuItem>
                    );
                  })}
                </MuiTextField>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid>
              <SubmissionAutocomplete formik={formik} fieldName="admins" />
            </MuiGrid>
            <MuiGrid>
              <SubmissionAutocomplete formik={formik} fieldName="observers" />
            </MuiGrid>
            <MuiGrid>
              <MuiGrid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <MuiGrid>
                  <FormButton
                    sx={{ fontSize: "16px" }}
                    onClick={handleCancel}
                    name={t("globals.form.actionButtons.cancel")}
                    variant="outlined"
                  />
                </MuiGrid>
                <MuiGrid>
                  <FormButton
                    name={t("globals.form.actionButtons.save")}
                    type="submit"
                    sx={{ fontSize: "16px" }}
                    variant="contained"
                    disabled={isSaving || !formik.isValid || !formik.dirty}
                  />
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </form>
      </MuiGrid>
    </MuiGrid>
  );
};

const SubmissionAutocomplete = ({ formik, fieldName }) => {
  const { loadPeopleOptions, loadPersonDetails } = usePeopleAPI();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [adminOptions, setAdminOptions] = useState([]);
  const [observerOptions, setObserverOptions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const options = {
    admins: adminOptions,
    observers: observerOptions,
  };

  const setOptions = {
    admins: setAdminOptions,
    observers: setObserverOptions,
  };

  const handleChangeInput = (event) => {
    const searchQuery = event.target.value.trim();

    loadPeopleOptions(searchQuery, setOptions[fieldName], setNoOptionsText);
  };

  const handleChangeSelection = (value) => {
    if (value.length > formik.values[fieldName].length) {
      // Load and add a new person to the Autocomplete
      loadPersonDetails(value[value.length - 1].profileId, formik, fieldName);
    } else {
      formik.setFieldValue(fieldName, value);
    }

    setOptions[fieldName]([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
  };

  if (!["admins", "observers"].includes(fieldName)) {
    return null;
  }

  return (
    <MuiAutocomplete
      name={fieldName}
      multiple
      value={formik.values[fieldName]}
      inputValue={inputValue}
      options={[...options[fieldName], ...formik.values[fieldName]]}
      filterSelectedOptions={true}
      filterOptions={(option, state) => option}
      getOptionLabel={(option) =>
        `${option.name}${option.uid ? ` (${option.uid})` : ""}`
      }
      isOptionEqualToValue={(option, value) =>
        parseInt(option.profileId) === parseInt(value.profileId)
      }
      noOptionsText={noOptionsText}
      onChange={(event, value) => handleChangeSelection(value)}
      onInputChange={(event, value) => setInputValue(value)}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t(`submission.create.form.fields.${fieldName}.label`)}
          onChange={(event) => handleChangeInput(event)}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(props, state, { inputValue }) => (
        <AutocompleteHighlight
          option={props}
          inputValue={inputValue}
          state={state}
        />
      )}
    />
  );
};

const AutocompleteHighlight = ({ option, inputValue, state }) => {
  const matches = match(state.name, inputValue);
  const parts = parse(state.name, matches);

  const { key, ...optionProps } = option;
  return (
    <li
      key={key}
      {...optionProps}
      style={{
        padding: "4px 16px",
        lineHeight: "18px",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {parts.map((part, index) => {
            return (
              <MuiTypography
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
                variant="body2"
                component="span"
              >
                {part.text}
              </MuiTypography>
            );
          })}
        </div>
        <div>
          <MuiTypography
            style={{
              fontWeight: 400,
              whiteSpace: "pre",
            }}
            variant="caption"
            component="span"
          >
            {state.title}
          </MuiTypography>
        </div>
      </div>
    </li>
  );
};
