import { useState, useEffect, useCallback } from "react";
import { Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
/** Material UI Components */
import { NavigateNext as MuiNavigateNext } from "@mui/icons-material";
import {
  Grid2 as MuiGrid,
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  Tooltip as MuiTooltip,
  Chip as MuiChip,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { Formik } from "formik";
import { format, isValid as isDateValid, parseISO } from "date-fns";
import * as Yup from "yup";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useBackButtonCallback } from "app/shared/ui/useBackButtonCallback";
/** Static Dropdown Values */
import { traineeTypeDropDown, EthnicityValues } from "app/shared/constants";
/** Custom Component */
import { ReactComponent as OrcidIdLogo } from "app/assets/icons/orcid-id.svg";
import { FormButton } from "app/shared/ui/Button/FormButton";
/** Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import {
  getEditTraineeCitizenshipValues,
  putTraineeDetailsByIdUpdate,
  getTraineeDetailsById,
} from "app/services/traineeService";
import { loadSubmissionFacultyList } from "app/services/facultyService";
import { getAutocompleteForEditTrainee } from "app/services/autoCompleteEditTraineeService";
/* Context and Component to show the error on UI */
import { Theme, useAlerts } from "common";
import { sharedStyles } from "app/shared/ui/sharedStyles";

let timeoutID;

// Delay after user pauses typing before calling API (in milliseconds)
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500;

// Minimum number of characters entered by user before calling API
export const AUTOCOMPLETE_MIN_INPUT = 2;

/**
 * Edit Trainee Component
 * @param {*} props
 * @returns
 */
export const EditTrainee = (props) => {
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();
  const navigate = useNavigate();

  // *********************** State Values ***********************

  // Initial Form State
  const [traineeFormValues, setTraineeFormValues] = useState({
    orcidId: "",
    commonsId: "",
    stanfordEmail: "",
    appointmentStartDate: null,
    appointmentEndDate: null,
    traineeType: "",
    submissionTraineeFaculty: [],
    submissionTraineeMentors: [],
    trainee: {},
    citizenship: {},
    ethnicity: "",
    grantEligible: false,
    hhsSupport: false,
    tgeSupport: false,
    r90Support: false,
  });

  const [loading, setLoading] = useState(false);
  const [citizenshipList, setCitizenshipList] = useState([]);
  const [citizenshipInputValue, setCitizenshipInputValue] = useState("");
  const [emailFieldError, setEmailFieldError] = useState({
    value: null,
    isError: false,
  });
  const [commonsIdFieldError, setCommonsIdFieldError] = useState({
    value: null,
    isError: false,
  });
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;

  // **** Faculty Autocomplete Dropdown ****
  const [facultyList, setFacultyList] = useState([]);

  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const [defaultCitizenshipOptions, setDefaultCitizenshipOptions] = useState(
    []
  );

  useEffect(() => {
    getEditTraineeCitizenshipValues("a", setDefaultCitizenshipOptions);
  }, [setDefaultCitizenshipOptions]);

  // ************ Set Form Values, with Trainee Details from API *********

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentInput, setDepartmentInputValue] = useState("DE");

  const handleDepartmentChange = (inputValue) => {
    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(async () => {
      if (inputValue.trim()?.length >= AUTOCOMPLETE_MIN_INPUT) {
        getAutocompleteForEditTrainee(
          {
            parameter: "departments",
            size: 10,
            targetValue: inputValue,
            includeOrgsWithAcademicPlanCodeOnly:
              traineeFormValues.traineeType === "PRE_DOC",
          },
          () => clearAlert(),
          (error) => setAlert("error", error.message),
          (data) => setDepartmentOptions(data.values)
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };
  useEffect(() => {
    handleDepartmentChange(departmentInput);
    // eslint-disable-next-line
  }, [departmentInput]);

  // **** Get Trainee Id ****
  const traineeId = useParams().trainee;

  const selectedSubmission = getSelectedSubmission();

  useEffect(() => {
    getTraineeDetailsById(
      setTraineeFormValues,
      setLoading,
      traineeId,
      selectedSubmission,
      setAlert,
      clearAlert
    );
  }, [traineeId, selectedSubmission, setAlert, clearAlert]);

  // ********* Fetch values for Faculty Autocomplete*********
  const handleCitizenshipAutoCompleteChange = (inputValue) => {
    setCitizenshipInputValue(inputValue);
    if (inputValue)
      getEditTraineeCitizenshipValues(inputValue, setCitizenshipList);
  };

  const handleFacultyAutocompleteChange = (event) => {
    let facultyVal = event.target.value.trim();

    loadSubmissionFacultyList(
      selectedSubmission,
      facultyVal,
      setFacultyList,
      setAlert,
      clearAlert,
      setNoOptionsText,
      t
    );
  };

  // **** Check if the Manual Typed Dates are valid ****
  const checkIfValidDates = (appointmentStartDate, appointmentEndDate) => {
    return appointmentStartDate && appointmentEndDate
      ? parseISO(appointmentEndDate).getTime() <
          parseISO(appointmentStartDate).getTime()
      : false;
  };

  // ** Redirect on cancel button click **
  const onCancelHandler = useCallback(() => {
    navigate("/trainees");
  }, [navigate]);

  // Handle back button
  useBackButtonCallback(onCancelHandler);

  // **** Form Validation Schema ****
  const EditTraineeFormValidation = Yup.object().shape({
    orcidId: Yup.string().when("hasSourceOrcid", {
      is: (val) => val === false,
      then: () =>
        Yup.string().test((val) => {
          if (val) {
            if (val.length === 19) return true;
            if (val.length < 19 && val.length > 0) return false;
          } else {
            return true;
          }
        }),
    }),
    stanfordEmail: Yup.string().when("currentTrainee", {
      is: (val) => val === true,
      then: () =>
        Yup.string()
          .email(t("trainee.notification.email"))
          .required("Required"),
    }),
    appointmentStartDate: Yup.date().nullable(),
    appointmentEndDate: Yup.date().nullable(),
    department: Yup.object().shape({
      code: Yup.string().required(),
    }),
    submissionTraineeFaculty: Yup.array().min(1).required("Required"),
    traineeType: Yup.string().required("Required"),
  });

  // Handle logic for citizenship and grant eligible checkbox
  const handleCitizenshipChange = (props) => {
    return props ? ["USA"].includes(props.code) : false;
  };

  const getOrcidIdTooltip = () => {
    return (
      <div>
        The ORCID iD field is the individual’s persistent digital identifier{" "}
        <MuiLink
          href="https://orcid.org/"
          sx={{
            "&&": {
              fontWeight: "bold",
              color: Theme.palette.common.white,
              "&:visited": {
                color: Theme.palette.common.white,
              },
            },
          }}
          target="_blank"
        >
          (<u>https://orcid.org/</u>)
        </MuiLink>{" "}
        that can be used to retrieve their professional information —
        affiliations, grants, and publications. <br />
        <br />
        The source for this information is Stanford’s ORCID Integration
        application{" "}
        <MuiLink
          href="https://authorize.stanford.edu/orcid/OrcidApp"
          target="_blank"
          sx={{
            "&&": {
              fontWeight: "bold",
              color: Theme.palette.common.white,
              "&:visited": {
                color: Theme.palette.common.white,
              },
            },
          }}
        >
          (<u>https://authorize.stanford.edu/orcid/OrcidApp</u>)
        </MuiLink>
        . <br />
        <br />
        If the ORCID iD is missing for an individual, we would encourage that it
        be updated in the source system, which requires each individual to
        verify/create their own ORCID iD directly in the application.
      </div>
    );
  };

  return (
    !loading &&
    traineeFormValues._links?.editSubmissionTraineeDetails && (
      <MuiGrid
        container
        wrap="nowrap"
        justify="flex-start"
        direction="column"
        spacing={2}
      >
        <MuiGrid>
          <MuiBreadcrumbs
            separator={<MuiNavigateNext fontSize="small" />}
            aria-label="breadcrumb"
            sx={sharedStyles.breadcrumb}
          >
            <RouteLink to="/trainees">{t("trainee.title")}</RouteLink>
            <MuiTypography color="textPrimary">
              {t("trainee.edit.title")}
            </MuiTypography>
          </MuiBreadcrumbs>
        </MuiGrid>

        <MuiGrid size={{ xs: 4 }}>
          <MuiTypography variant="h5">
            {traineeFormValues.trainee
              ? `${traineeFormValues.trainee.fullName}`
              : ""}
          </MuiTypography>
          <MuiTypography variant="h5">
            {traineeFormValues.traineeType
              ? `${t(traineeTypeDropDown[traineeFormValues.traineeType])}`
              : ""}
          </MuiTypography>
        </MuiGrid>

        <MuiGrid>
          <Formik
            initialValues={traineeFormValues}
            enableReinitialize={true}
            validationSchema={EditTraineeFormValidation}
            validateOnMount={true}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              putTraineeDetailsByIdUpdate(
                setEmailFieldError,
                setCommonsIdFieldError,
                selectedSubmission,
                traineeId,
                values,
                navigate,
                setAlert,
                clearAlert,
                t
              );
              setSubmitting(false);
              setEmailFieldError(false);
            }}
          >
            {(formik) => {
              const {
                values,
                isSubmitting,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                isValid,
                dirty,
              } = formik;

              const handleChangeOrcID = (event) => {
                let { value } = event.target;

                // remove any non-alphanumeric characters except hyphens
                value = value.replace(/[^\d]/g, "");

                // insert hyphens every 4 characters
                let formattedValue = "";
                for (let i = 0; i < value.length; i++) {
                  if (i > 0 && i % 4 === 0) {
                    formattedValue += "-";
                  }
                  formattedValue += value[i];
                }
                // update the state with formattedValue
                formik.setFieldValue("orcidId", formattedValue);
              };

              return (
                <form onSubmit={handleSubmit}>
                  <MuiGrid
                    container
                    size={{ xs: 10, sm: 8, md: 6, lg: 5, xl: 4 }}
                    direction="column"
                    justify="space-between"
                    spacing={2}
                  >
                    <MuiGrid size={{ xs: 12 }}>
                      <MuiGrid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <MuiGrid size={{ xs: 11 }}>
                          <MuiTextField
                            id="orcidId"
                            name="orcidId"
                            label={`${t("trainee.edit.traineeForm.orcidId")}`}
                            value={values.orcidId || ""}
                            onChange={handleChangeOrcID}
                            onBlur={handleBlur}
                            helperText={touched.orcidId ? errors.orcidId : ""}
                            error={touched.orcidId && Boolean(errors.orcidId)}
                            variant="outlined"
                            fullWidth
                            disabled={values.hasSourceOrcid}
                            inputProps={{ maxLength: 19 }}
                            autoComplete="off"
                          />
                        </MuiGrid>
                        <MuiGrid
                          size={{ xs: 1 }}
                          sx={{
                            "&&": {
                              paddingTop: "18px",
                            },
                          }}
                        >
                          <MuiTooltip
                            placement="right"
                            sx={{ fontSize: "14px", maxWidth: "500px" }}
                            title={getOrcidIdTooltip()}
                          >
                            <div style={{ height: "20px", width: "20px" }}>
                              <OrcidIdLogo />
                            </div>
                          </MuiTooltip>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiTextField
                        id="commonsId"
                        name="commonsId"
                        label={`${t("trainee.edit.traineeForm.commonsID")}`}
                        variant="outlined"
                        error={commonsIdFieldError.isError}
                        helperText={
                          commonsIdFieldError.isError &&
                          `${t("trainee.edit.traineeForm.commonsIdError")}`
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const commonIdVal = e.target.value;
                          setCommonsIdFieldError({
                            ...commonsIdFieldError,
                            isError:
                              commonsIdFieldError.isError &&
                              commonsIdFieldError.value === commonIdVal,
                          });

                          formik.setFieldValue("commonsId", commonIdVal);
                        }}
                        value={values.commonsId}
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 20 }}
                        onKeyPress={(e) => {
                          if (e.charCode === 32) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiAutocomplete
                        name="submissionTraineeFaculty"
                        multiple
                        value={values.submissionTraineeFaculty}
                        options={[
                          ...facultyList,
                          ...(values.submissionTraineeFaculty || []),
                        ]}
                        filterSelectedOptions={true}
                        filterOptions={(option, state) => option}
                        getOptionLabel={(option) =>
                          `${option.displayText || option.fullName}${
                            option.value?.sunetId || option.sunetId
                              ? ` (${option.value?.sunetId || option.sunetId})`
                              : ""
                          }`
                        }
                        isOptionEqualToValue={(option, value) =>
                          (parseInt(option.univId) ||
                            parseInt(option.value?.universityId)) ===
                          (parseInt(value.univId) ||
                            parseInt(value.value?.universityId))
                        }
                        noOptionsText={noOptionsText}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            "submissionTraineeFaculty",
                            value
                          );
                          setFacultyList([]);
                          setNoOptionsText(
                            t("globals.autocomplete.helperText")
                          );
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t("trainee.edit.traineeForm.faculty")}*`}
                            onChange={(event, value) => {
                              handleFacultyAutocompleteChange(event);
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        renderOption={(props, state, { inputValue }) => (
                          <AutocompleteOption
                            option={props}
                            inputValue={inputValue}
                            state={state}
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiAutocomplete
                        name="submissionTraineeMentors"
                        multiple
                        value={values.submissionTraineeMentors}
                        options={values.submissionTraineeMentors}
                        getOptionLabel={(option) =>
                          `${option.fullName}${
                            option.sunetId ? ` (${option.sunetId})` : ""
                          }`
                        }
                        filterSelectedOptions={true}
                        filterOptions={(option, state) => option}
                        isOptionEqualToValue={(option, value) =>
                          (parseInt(option.univId) ||
                            parseInt(option.value?.universityId)) ===
                          (parseInt(value.univId) ||
                            parseInt(value.value?.universityId))
                        }
                        noOptionsText={noOptionsText}
                        disabled={true}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <MuiChip
                              sx={{
                                margin: "3px",
                                maxWidth: "calc(100% - 6px)",
                              }}
                              label={`${option.fullName}${
                                option.sunetId ? ` (${option.sunetId})` : ""
                              }`}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t("trainee.edit.traineeForm.mentor")}`}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiAutocomplete
                        id="department"
                        name="department"
                        value={values.department}
                        options={
                          departmentOptions?.length > 0
                            ? departmentOptions.map((deptObj) => {
                                return deptObj.value;
                              })
                            : []
                        }
                        getOptionLabel={(option) =>
                          Object.keys(option).length > 0
                            ? `${option.name} (${option.code})`
                            : ""
                        }
                        onChange={(e, value) => {
                          formik.setFieldValue("department", value);
                        }}
                        getOptionDisabled={(option) =>
                          values.departments &&
                          values.departments.some((el) => {
                            return el.code === option.code;
                          })
                        }
                        noOptionsText={t("globals.autocomplete.helperText")}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            disabled={loading}
                            label={`${t(
                              "trainee.edit.traineeForm.departments"
                            )}*`}
                            variant="outlined"
                            onChange={(e) =>
                              setDepartmentInputValue(e.target.value)
                            }
                            fullWidth
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiTextField
                        id="stanfordEmail"
                        label={`${t("trainee.edit.traineeForm.stanfordEmail")}`}
                        value={values.stanfordEmail}
                        onBlur={handleBlur}
                        helperText={
                          errors.stanfordEmail || emailFieldError.isError
                            ? errors.stanfordEmail ||
                              t("trainee.notification.email")
                            : ""
                        }
                        error={errors.stanfordEmail || emailFieldError.isError}
                        onChange={(e) => {
                          const stanfordEmail = e.target.value;
                          setEmailFieldError({
                            ...emailFieldError,
                            isError:
                              emailFieldError.isError &&
                              emailFieldError.value === stanfordEmail,
                          });

                          formik.setFieldValue("stanfordEmail", stanfordEmail);
                        }}
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
                        <MuiDatePicker
                          maxDate={
                            parseISO(values.appointmentEndDate) || undefined
                          }
                          inputVariant="outlined"
                          label={`${t(
                            "trainee.edit.traineeForm.trainingStartDate"
                          )}`}
                          name="appointmentStartDate"
                          inputProps={{ autoComplete: "off" }}
                          clearable
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline-start-from"
                          value={
                            values.appointmentStartDate &&
                            parseISO(values.appointmentStartDate)
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              "appointmentStartDate",
                              isDateValid(date)
                                ? format(new Date(date), "yyyy-MM-dd")
                                : date
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onError={(reason) => {
                            if (reason === "maxDate") {
                              setStartDateError(
                                t("trainee.edit.traineeForm.maxDateMessage")
                              );
                            } else if (reason === "invalidDate") {
                              setStartDateError(
                                t("trainee.edit.traineeForm.invalidDateFormat")
                              );
                            } else {
                              setStartDateError(reason);
                            }
                          }}
                          slotProps={{
                            textField: {
                              onBlur: handleBlur,
                              variant: "outlined",
                              error: Boolean(startDateError),
                              helperText: startDateError,
                            },
                          }}
                          sx={{ width: "100%" }}
                        />
                      </MuiLocalizationProvider>
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
                        <MuiDatePicker
                          inputVariant="outlined"
                          label={`${t(
                            "trainee.edit.traineeForm.trainingEndDate"
                          )}`}
                          name="appointmentEndDate"
                          inputProps={{ autoComplete: "off" }}
                          clearable
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline-start-from"
                          minDate={
                            parseISO(values.appointmentStartDate) || undefined
                          }
                          value={
                            values.appointmentEndDate &&
                            parseISO(values.appointmentEndDate)
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              "appointmentEndDate",
                              isDateValid(date)
                                ? format(new Date(date), "yyyy-MM-dd")
                                : date
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onError={(reason) => {
                            if (reason === "minDate") {
                              setEndDateError(
                                t("trainee.edit.traineeForm.minDateMessage")
                              );
                            } else if (reason === "invalidDate") {
                              setEndDateError(
                                t("trainee.edit.traineeForm.invalidDateFormat")
                              );
                            } else {
                              setEndDateError(reason);
                            }
                          }}
                          slotProps={{
                            textField: {
                              onBlur: handleBlur,
                              variant: "outlined",
                              error: Boolean(endDateError),
                              helperText: endDateError,
                            },
                          }}
                          sx={{ width: "100%" }}
                        />
                      </MuiLocalizationProvider>
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiAutocomplete
                        id="citizenship"
                        name="citizenship"
                        options={
                          citizenshipInputValue
                            ? citizenshipList
                            : defaultCitizenshipOptions
                        }
                        noOptionsText={t(
                          "trainee.edit.traineeForm.citizenshipHelperText"
                        )}
                        getOptionLabel={(option) => option.name ?? ""}
                        value={values.citizenship}
                        clearOnEscape={true}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          formik.setFieldValue("citizenship", value ?? {});
                          formik.setFieldValue(
                            "grantEligible",
                            handleCitizenshipChange(value)
                          );
                          if (!handleCitizenshipChange(value)) {
                            formik.setFieldValue("tgeSupport", false);
                          }
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={t("trainee.edit.traineeForm.citizenship")}
                            variant="outlined"
                            onChange={(e) => {
                              handleCitizenshipAutoCompleteChange(
                                e.target.value
                              );
                            }}
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiAutocomplete
                        options={Object.keys(EthnicityValues)}
                        getOptionLabel={(option) => t(EthnicityValues[option])}
                        id="ethnicity"
                        value={values.ethnicity}
                        clearOnEscape={true}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          formik.setFieldValue("ethnicity", value);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={t("trainee.edit.traineeForm.ethnicity")}
                            variant="outlined"
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid container size={{ xs: 12 }}>
                      <MuiGrid>
                        <MuiFormControlLabel
                          control={
                            <MuiCheckbox
                              name="grantEligible"
                              disableRipple={true}
                              value={values.grantEligible}
                              onChange={(e) => {
                                if (values.grantEligible) {
                                  formik.setFieldValue(
                                    "tgeSupport",
                                    e.target.checked
                                  );
                                }
                                formik.setFieldValue(
                                  "grantEligible",
                                  e.target.checked
                                );
                              }}
                              checked={values.grantEligible}
                              color="primary"
                            />
                          }
                          label={`${t(
                            "trainee.edit.traineeForm.grantEligible"
                          )}`}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiFormControlLabel
                        sx={{
                          "& .MuiTypography-body1": {
                            marginTop: "-10px",
                            color: Theme.palette.text.primary,
                          },
                        }}
                        control={
                          <MuiCheckbox
                            name="hhsSupport"
                            sx={{
                              paddingTop: "0px",
                            }}
                            disableRipple={true}
                            value={values.hhsSupport}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "hhsSupport",
                                e.target.checked
                              );
                            }}
                            checked={values.hhsSupport}
                            color="primary"
                          />
                        }
                        label={`${t("trainee.edit.traineeForm.hhsSupport")}`}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiFormControlLabel
                        control={
                          <MuiCheckbox
                            name="tgeSupport"
                            disableRipple={true}
                            value={values.tgeSupport}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "tgeSupport",
                                e.target.checked
                              );
                            }}
                            checked={
                              !values.grantEligible ? false : values.tgeSupport
                            }
                            color="primary"
                          />
                        }
                        disabled={!values.grantEligible}
                        label={`${t("trainee.edit.traineeForm.tgeSupport")}`}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiFormControlLabel
                        sx={{
                          "& .MuiTypography-body1": {
                            marginTop: "-10px",
                            color: Theme.palette.text.primary,
                          },
                        }}
                        control={
                          <MuiCheckbox
                            name="r90Support"
                            sx={{
                              paddingTop: "0px",
                            }}
                            disableRipple={true}
                            value={values.r90Support}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "r90Support",
                                e.target.checked
                              );
                            }}
                            checked={values.r90Support}
                            color="primary"
                          />
                        }
                        label={`${t("trainee.edit.traineeForm.r90Support")}`}
                      />
                    </MuiGrid>
                    <MuiGrid size={{ xs: 11 }}>
                      <MuiGrid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <MuiGrid>
                          <FormButton
                            onClick={onCancelHandler}
                            name={t("globals.form.actionButtons.cancel")}
                            variant="outlined"
                          />
                        </MuiGrid>
                        <MuiGrid>
                          <FormButton
                            name={t("globals.form.actionButtons.save")}
                            variant="contained"
                            type="submit"
                            disabled={
                              isSubmitting ||
                              !dirty ||
                              !isValid ||
                              emailFieldError.isError ||
                              commonsIdFieldError.isError ||
                              checkIfValidDates(
                                values.appointmentStartDate,
                                values.appointmentEndDate
                              )
                            }
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </form>
              );
            }}
          </Formik>
        </MuiGrid>
      </MuiGrid>
    )
  );
};

/* Options to be shown in Faculty Autocomplete
 * Values in the list are to be shown in the format:
 *    - Faculty Name
 *    - Rank
 *    - Department
 * With parts and matches, the value typed in the Input Field,
 * is shown higlighted in the Options List for matching values
 */
const AutocompleteOption = ({ option, inputValue, state }) => {
  const matches = match(state.displayText, inputValue);
  const parts = parse(state.displayText, matches);

  const { key, ...optionProps } = option;
  return (
    <div key={key} {...optionProps}>
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      <div>
        {[state.value.rank, state.value.departmentName]
          .filter(Boolean)
          .join(", ")}
      </div>
    </div>
  );
};
