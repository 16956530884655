import { Button as MuiButton } from "@mui/material";
import { Theme } from "common";
import styled from "styled-components";

export const outputTableStyles = {
  fixHeader: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
    "& tbody tr:last-child td:first-of-type": {
      fontWeight: 700,
    },
  },
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: Theme.palette.primary.light,
      color: Theme.palette.text.primary,
      fontWeight: 600,
    },
    "& .MuiTableCell-root": {
      alignSelf: "left",
    },
  },
  actionButton: {
    "& .MuiButton-root": {
      height: "40px",
    },
  },
  newLine: {
    whiteSpace: "pre-line",
  },
};

export const TopOutputDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiTableContainer-root {
    box-shadow: -1px 2px 3px 2px #c4c4c466;
    border: 1px solid #e0e0e0;
    margin: 16px 0;
    overflow-x: hidden;
  }
`;

export const TopInputPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const OutputActionDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
`;

export const SubmissionsDivStyled = styled.div`
  .MuiFormControl-root {
    width: 140%;
    margin-bottom: 16px;
  }
`;
export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const ActionButton = styled(MuiButton)`
  height: 40px;
  alignself: flex-end;
`;

export const title = {
  width: "30%",
};

export const NewLine = {
  whiteSpace: "pre-line",
};

export const zIndexOverride = {
  zIndex: "0",
};
