import { useState } from "react";
import { Box as MuiBox, Typography as MuiTypography } from "@mui/material";
import { FilterPanel } from "app/shared/ui/FilterPanel/FilterPanel";
import { MetaDataInformation } from "app/shared/ui/MetaDataInformation/MetaDataInformation";

export const Page = ({
  title = "",
  filter = null,
  main,
  metaDataDescription = null,
}) => {
  const [open, setOpen] = useState(true);

  function handleDrawerToggle() {
    setOpen((prev) => !prev);
  }

  return (
    <MuiBox display="flex" width="100%">
      {filter && (
        <FilterPanel open={open} handleDrawerToggle={handleDrawerToggle}>
          {filter}
        </FilterPanel>
      )}
      <MuiBox
        gap="16px"
        width="100%"
        display="flex"
        paddingY="16px"
        paddingLeft="24px"
        flexDirection="column"
        sx={{
          minWidth: "1400px",
          "@media (max-width: 1700px)": {
            paddingRight: "24px",
          },
        }}
      >
        {title && (
          <MuiBox display="flex" alignItems="baseline">
            <MuiTypography variant="h1">{title}</MuiTypography>
            <MetaDataInformation
              variant="h5"
              modalDescription={metaDataDescription}
            />
          </MuiBox>
        )}
        {main}
      </MuiBox>
    </MuiBox>
  );
};
