import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { Check as MuiCheckIcon } from "@mui/icons-material";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";

import { useBrowserStorage } from "app/services/StorageService/StorageHelper";
import { STORAGE_TYPE, STORAGE_NAMES } from "app/shared/constants";

export const SubmissionSelect = ({
  submissionList,
  handleChangeSubmission,
}) => {
  const [getSelectedSubmission, setSelectedSubmission] =
    useSelectedSubmission().value;
  const [, setSelectedSubmissionYear] = useSelectedSubmission().year;
  const [, setSelectedSubmissionName] = useSelectedSubmission().name;
  const [, setSelectedSubmissionMonth] = useSelectedSubmission().month;
  const [selectedValue, setSelectedValue] = useState("");
  const [submissionOptions, setSubmissionOptions] = useState([]);

  const { t } = useTranslation();
  const { removeItem } = useBrowserStorage(STORAGE_TYPE.SESSION);

  const handleSubmissionIdChange = () => {
    for (let key in STORAGE_NAMES) {
      if (key !== STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS) {
        removeItem(STORAGE_NAMES[key]);
      }
    }
  };

  const handleChange = (event, newValue) => {
    const value = newValue ? newValue._links.self.href.split("/").pop() : "";

    handleSubmissionIdChange();

    setSelectedSubmission(value);
    setSelectedValue(newValue ?? "");
    setSelectedSubmissionName(newValue?.name ?? "");
    setSelectedSubmissionYear(newValue?.year ?? "");
    setSelectedSubmissionMonth(newValue?.month ?? "");
    if (handleChangeSubmission) {
      handleChangeSubmission();
    }
  };

  const submissionExists = useCallback(() => {
    return submissionOptions.find(function (submissionValue) {
      if (
        submissionValue._links.self.href.split("/").pop() ===
        getSelectedSubmission()
      ) {
        return submissionValue;
      }
      return null;
    });
  }, [getSelectedSubmission, submissionOptions]);

  useEffect(() => {
    if (submissionList && submissionList.length) {
      const submissionVal = submissionExists();
      if (submissionVal) {
        setSelectedValue(submissionVal);
      }
    }
  }, [submissionExists, submissionList]);

  useEffect(() => {
    if (submissionList && submissionList.length) {
      const extendedOptionList = submissionList.map((item) => {
        const submissionMonth =
          item.month.toString().length < 2 ? `0${item.month}` : item.month;
        return {
          ...item,
          displayText: `${item.name}, ${submissionMonth}/${item.year}`,
          value: {},
        };
      });

      setSubmissionOptions(extendedOptionList);
    }
  }, [submissionList]);

  return (
    <MuiAutocomplete
      id="submission-selection"
      disableClearable
      autoHighlight
      clearOnEscape
      blurOnSelect
      options={submissionOptions}
      getOptionLabel={(option) => option?.displayText || ""}
      value={selectedValue}
      onChange={(event, newValue) => {
        handleChange(event, newValue);
      }}
      renderOption={(props, state, { inputValue, selected }) => (
        <AutocompleteOption
          option={props}
          inputValue={inputValue}
          state={state}
        >
          {selected && (
            <MuiCheckIcon
              style={{
                fill: "#009ABB",
                marginLeft: "auto",
                marginRight: "0",
              }}
            />
          )}
        </AutocompleteOption>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t("faculty.mainView.submission")}
          variant="outlined"
          sx={{
            fontWeight: 500,
            lineHeight: "22.5px",
            width: "100%",
          }}
        />
      )}
      sx={{
        "& .MuiAutocomplete-popupIndicatorOpen": {
          // Stop MUI from rotating the icon by rotating it back
          rotate: "180deg",
        },
        "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
          // Move dropdown arrow and clear X up to horizontal center
          height: "auto",
        },
        "& .MuiInputLabel-root, .MuiInputBase-input": {
          fontSize: "14.5px",
        },
      }}
    />
  );
};
