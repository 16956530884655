import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow,
  Paper as MuiPaper,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  TableSortLabel as MuiTableSortLabel,
  Typography as MuiTypography,
  Grid2 as MuiGrid,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@mui/material";
import {
  ContentCopy as MuiContentCopyIcon,
  AddCircle as MuiAddCircleIcon,
  Delete as MuiDeleteIcon,
  Edit as MuiEditIcon,
} from "@mui/icons-material";
import PropTypes from "prop-types";
/** Custom Components */
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import { userRoles } from "app/shared/constants";
/** Services */
import { useUsers, useAlerts, Theme } from "common";
import {
  getManageSubmissionsList,
  deleteSubmissionById,
  getAllSubmissions,
} from "app/services/submissionService";
import { useManageSubmissionsContext } from "app/services/manageSubmissionsContext";
import { CopySubmissionDialog } from "../CopySubmission/CopySubmissionDialog";

const SubmissionTableHeader = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead
      sx={{
        "& .MuiTableSortLabel-root": {
          color: "white !important",
        },
        "& .MuiTableSortLabel-root:hover": {
          color: "white",
        },
        "&.Mui-active": {
          color: "white",
        },
        "& .MuiTableSortLabel-icon": {
          color: "white !important",
          alignSelf: "center",
        },
      }}
    >
      <MuiTableRow>
        <MuiTableCell
          align={"left"}
          key="name"
          sortDirection={orderBy === "name" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "name"}
            direction={orderBy === "name" ? order : "asc"}
            onClick={createSortHandler("name")}
          >
            {t("submission.manage.submissionList.tableColumns.submissionName")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align={"left"}
          key="year"
          sortDirection={orderBy === "year" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "year"}
            direction={orderBy === "year" ? order : "asc"}
            onClick={createSortHandler("year")}
          >
            {t(
              "submission.manage.submissionList.tableColumns.submissionYearMonth"
            )}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align={"left"} key="spoNumbers">
          {t("submission.manage.submissionList.tableColumns.spoNumber")}
        </MuiTableCell>
        <MuiTableCell align={"left"} key="submissionAdmins">
          {t("submission.manage.submissionList.tableColumns.submissionAdmin")}
        </MuiTableCell>
        <MuiTableCell align={"left"} key="observers">
          {t("submission.manage.submissionList.tableColumns.observer")}
        </MuiTableCell>
        <MuiTableCell align={"left"} key="actions">
          {t("submission.manage.submissionList.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

SubmissionTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// ************************* Manage Submission *************************
export const ManageSubmission = (props) => {
  const { t } = useTranslation();
  const [snackbar, setSnackBar] = useState(false);
  const [open, setOpen] = useState();

  const { currentUser } = useUsers();

  const { submissionPreference, setSubmissionPreference } =
    useManageSubmissionsContext();

  const initialSortValues = submissionPreference.manageSubmissions.sort;

  const [orderBy, setOrderBy] = useState(initialSortValues.orderBy || "name");
  const [order, setOrder] = useState(initialSortValues.order || "asc");
  const initialPaginationValues =
    submissionPreference.manageSubmissions.pagination;

  const [page, setPage] = useState(initialPaginationValues.page || 0);
  const [pageSize, setPageSize] = useState(
    initialPaginationValues.pageSize || 10
  );

  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setLoading] = useState(true);

  // State variable for submission list loading
  const [loadingSubmissionList, setLoadingSubmissionList] = useState(true);
  // State variable for submission list for copy submission dialog
  const [submissionsList, setSubmissionsList] = useState([]);
  // State variable for copy submission dialog
  const [openCopySubmissionDialog, setOpenCopySubmissionDialog] =
    useState(false);
  // Function to close the copy submission dialog and reset the submission list
  const closeCopySubmissionDialog = () => {
    setSubmissionsList([]);
    setOpenCopySubmissionDialog(false);
  };

  const { setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    if (page !== submissionPreference.manageSubmissions.pagination.page) {
      setPage(submissionPreference.manageSubmissions.pagination.page);
    }
  }, [page, submissionPreference.manageSubmissions.pagination.page]);

  const stringifiedSubmissionPreference = JSON.stringify(submissionPreference);

  useEffect(() => {
    getManageSubmissionsList(
      setTableData,
      setTotalCount,
      setLoading,
      setAlert,
      clearAlert,
      submissionPreference
    );
    // eslint-disable-next-line
  }, [page, pageSize, orderBy, order, stringifiedSubmissionPreference]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);

    setSubmissionPreference({
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        sort: {
          orderBy: property,
          order: isAsc,
        },
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    setSubmissionPreference({
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        pagination: {
          ...submissionPreference.manageSubmissions.pagination,
          page: newPage,
        },
      },
    });
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    let pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }

    setSubmissionPreference({
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        pagination: {
          ...submissionPreference.manageSubmissions.pagination,
          page: pageValue,
          pageSize: parseInt(event.target.value, 10),
        },
      },
    });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDeleteSubmission = (submissionId) => {
    setOpen(false);
    deleteSubmissionById(
      submissionId,
      setSnackBar,
      submissionPreference,
      setSubmissionPreference,
      setLoading,
      setAlert,
      clearAlert
    );
  };

  const submissionYearMonth = (submissionYear, month) => {
    const submissionMonth = month.toString().length < 2 ? `0${month}` : month;
    return `${submissionMonth}/${submissionYear}`;
  };

  return (
    <MuiGrid container justify="flex-start" direction="column" spacing={1}>
      {snackbar && (
        <ShowSnackBar
          message={t("globals.list.actionIcons.delete.successMessage")}
        />
      )}

      <MuiGrid>
        <MuiGrid container direction="row" justifyContent="flex-end" gap="16px">
          {currentUser?.permissions?.CREATE_SUBMISSION && (
            <MuiGrid>
              <MuiButton
                variant="contained"
                color="primary"
                onClick={() => {
                  // Open the copy submission dialog and get all submissions
                  setOpenCopySubmissionDialog(true);
                  getAllSubmissions(
                    setAlert,
                    clearAlert,
                    setSubmissionsList,
                    setLoadingSubmissionList
                  );
                }}
              >
                <MuiContentCopyIcon fontSize="small" />
                &nbsp; {t("submission.copy.copyButtonText")}
              </MuiButton>
            </MuiGrid>
          )}
          <CopySubmissionDialog
            open={openCopySubmissionDialog}
            onClose={closeCopySubmissionDialog}
            loading={loadingSubmissionList}
            submissionsList={submissionsList}
          />
          <MuiGrid>
            {currentUser?.permissions?.CREATE_SUBMISSION && (
              <Link to="/admin/submissions/new">
                <MuiButton variant="contained" color="primary">
                  <MuiAddCircleIcon />
                  &nbsp;{t("submission.manage.ctaTextForCreateSubmission")}
                </MuiButton>
              </Link>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid>
        <MuiTableContainer
          component={MuiPaper}
          sx={{ width: "100%", minWidth: "100em", overflowX: "hidden" }}
        >
          <MuiTable aria-label="customized table" stickyHeader>
            <SubmissionTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <MuiTableBody>
              {isLoading ? (
                <MuiTableRow>
                  <MuiTableCell colSpan="5">
                    <MuiTypography
                      variant="h6"
                      color="textSecondary"
                      align="center"
                      paragraph={false}
                    >
                      {t("submission.manage.submissionList.dataLoadingText")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              ) : !isLoading && tableData?.length > 0 ? (
                tableData.map((row) => (
                  <MuiTableRow key={row.name + row.year}>
                    <MuiTableCell component="th" scope="row">
                      {row.name}
                    </MuiTableCell>
                    <MuiTableCell align="left">
                      {submissionYearMonth(row.year, row.month)}
                    </MuiTableCell>
                    <MuiTableCell align="left">
                      {row.grant ? (
                        <MuiTooltip
                          title={`${row.grant.spoNumber}, ${row.grant.grantNumber}, ${row.grant.title}`}
                        >
                          <span>{row.grant.spoNumber}</span>
                        </MuiTooltip>
                      ) : (
                        ""
                      )}
                    </MuiTableCell>
                    <MuiTableCell align="left">
                      {row.assignees &&
                        row.assignees
                          .filter(
                            (assignee) =>
                              assignee.role === userRoles.submissionAdmin
                          )
                          .map((assignee) => assignee.name)
                          .join(", ")}
                    </MuiTableCell>

                    <MuiTableCell align="left">
                      {row.assignees &&
                        row.assignees
                          .filter(
                            (assignee) => assignee.role === userRoles.observer
                          )
                          .map((assignee) => assignee.name)

                          .join(", ")}
                    </MuiTableCell>

                    <MuiTableCell
                      align="left"
                      sx={{
                        width: 30,
                        padding: "5px 0px 0px 0px",
                        "& .MuiIconButton-root": {
                          fontSize: "0.5rem",
                        },
                      }}
                    >
                      <span style={{ display: "flex" }}>
                        {row._links.edit ? (
                          <MuiTooltip
                            title={t("globals.list.actionIcons.edit.tooltip")}
                          >
                            <MuiIconButton aria-label="edit ">
                              <Link
                                to={`/admin/submissions/${row._links.edit.href.substring(
                                  row._links.edit.href.lastIndexOf("/") + 1,
                                  row._links.edit.href.length
                                )}/edit`}
                              >
                                <MuiEditIcon fontSize="small" />
                              </Link>
                            </MuiIconButton>
                          </MuiTooltip>
                        ) : (
                          ""
                        )}
                        {row._links.delete ? (
                          <MuiTooltip
                            title={t("globals.list.actionIcons.delete.tooltip")}
                          >
                            <MuiIconButton
                              aria-label="delete"
                              onClick={() => {
                                setOpen(row.name);
                                setSnackBar(false);
                              }}
                            >
                              <MuiDeleteIcon fontSize="small" />
                            </MuiIconButton>
                          </MuiTooltip>
                        ) : (
                          ""
                        )}
                        <MuiDialog
                          sx={{
                            "& .MuiDialogTitle-root": {
                              paddingBottom: 0,
                            },
                            "& span.MuiTypography-root": {
                              color: Theme.palette.text.primary,
                            },
                            "& .MuiDialogActions-root": {
                              paddingTop: 0,
                              "& .MuiButton-root:first-of-type": {
                                marginRight: "25px",
                              },
                            },
                          }}
                          open={row.name === open}
                          onClose={handleCloseDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <MuiDialogTitle id="alert-dialog-title">
                            {t(
                              "globals.list.actionIcons.delete.dialog.dialogTitle"
                            )}
                          </MuiDialogTitle>
                          <MuiDialogContent>
                            <MuiDialogContentText id="alert-dialog-description">
                              {t(
                                "globals.list.actionIcons.delete.dialog.dialogMessage"
                              )}
                              <MuiTypography component="span">
                                {" "}
                                "{row.name}"?
                              </MuiTypography>
                            </MuiDialogContentText>
                          </MuiDialogContent>
                          <MuiDialogActions>
                            <MuiGrid container justifyContent="flex-end">
                              <MuiButton
                                onClick={() => handleCloseDialog()}
                                color="primary"
                              >
                                {t(
                                  "globals.list.actionIcons.delete.dialog.dialogCancel"
                                )}
                              </MuiButton>
                              <MuiButton
                                onClick={() =>
                                  handleDeleteSubmission(
                                    row._links.delete.href.substring(
                                      row._links.delete.href.lastIndexOf("/") +
                                        1,
                                      row._links.delete.href.length
                                    )
                                  )
                                }
                                color="primary"
                              >
                                {t(
                                  "globals.list.actionIcons.delete.dialog.dialogDelete"
                                )}
                              </MuiButton>
                            </MuiGrid>
                          </MuiDialogActions>
                        </MuiDialog>
                      </span>
                    </MuiTableCell>
                  </MuiTableRow>
                ))
              ) : (
                <MuiTableRow>
                  <MuiTableCell colSpan="6">
                    <MuiTypography
                      variant="body2"
                      color="inherit"
                      align="center"
                    >
                      {t("globals.list.messages.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
          {isLoading && (
            <MuiBox sx={{ width: "100%" }}>
              <MuiLinearProgress />
            </MuiBox>
          )}
          {totalCount > 0 && (
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={pageSize}
              page={parseInt(page) || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageSize}
            />
          )}
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};
