import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  Button as MuiButton,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { CloudDownload as MuiCloudDownloadIcon } from "@mui/icons-material";
/** Custom Components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Service */
import {
  getOutputTable4,
  getExportData,
  getOutputSubmissionsList,
} from "app/services/outputService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { outputTableStyles } from "./OutputTable4.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const OutputTable4 = (props) => {
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const { t } = useTranslation();
  const outputTable4Columns = "output.mainView.list.tableColumns";

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { handleSubmissionUpdate } = useSelectedSubmission();
  const selectedSubmission = getSelectedSubmission();
  const [outputTable4Data, setOutputTable4Data] = useState([]);
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  /**
   * API call to get OutputTable4 data
   */
  useEffect(() => {
    getOutputTable4(
      selectedSubmission,
      setOutputTable4Data,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [selectedSubmission]);

  const handleChangeSubmission = () => {
    setReload(!reload);
    handleSubmissionUpdate();
  };

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      "Table_4",
      "table-four",
      getSelectedSubmission(),
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  return (
    <MuiGrid container direction="column">
      <MuiGrid sx={sharedStyles.outputAutoCompleteWrapper}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        container
        justifyContent="flex-end"
        sx={{ marginBottom: "16px" }}
      >
        <MuiButton
          variant="outlined"
          color="secondary"
          onClick={handleExport}
          disabled={exportLoading || !outputTable4Data}
        >
          {exportLoading && (
            <Spinner visible={true} style={sharedStyles.exportSpinnerStyles} />
          )}
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}{" "}
        </MuiButton>
      </MuiGrid>
      <MuiGrid container>
        <MuiTableContainer
          component={MuiPaper}
          sx={{
            ...outputTableStyles.fixHeader,
            ...outputTableStyles.blueHeader,
          }}
        >
          <MuiTable aria-label="customized table" stickyHeader>
            <MuiTableHead>
              <MuiTableRow>
                <MuiTableCell>
                  {t(`${outputTable4Columns}.facultyMembers`)}
                </MuiTableCell>
                <MuiTableCell>
                  {t(`${outputTable4Columns}.fundingSource`)}
                </MuiTableCell>
                <MuiTableCell>
                  {t(`${outputTable4Columns}.grantNumber`)}
                </MuiTableCell>
                <MuiTableCell width="7%">
                  {t(`${outputTable4Columns}.roleOnProject`)}
                </MuiTableCell>
                <MuiTableCell width="35%">
                  {t(`${outputTable4Columns}.grantTitle`)}
                </MuiTableCell>
                <MuiTableCell>
                  {t(`${outputTable4Columns}.projectPeriod`)}
                </MuiTableCell>
                <MuiTableCell align={"right"}>
                  {t(`${outputTable4Columns}.currentYearDirectCost`)}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {!loading &&
                outputTable4Data?.map((row, i) => {
                  return outputTable4Data.length - 1 !== i ? (
                    <MuiTableRow key={i}>
                      <MuiTableCell>
                        {row.outputTableFourDto.facultyMember
                          ? row.outputTableFourDto.facultyMember.fullName
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.outputTableFourDto.fundingSource
                          ? row.outputTableFourDto.fundingSource.name
                          : "None"}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.outputTableFourDto.grant
                          ? row.outputTableFourDto.grant.grantNumber
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.outputTableFourDto.roleOnProject
                          ? row.outputTableFourDto.roleOnProject
                              .map((role) => role["name"])
                              .join(", ")
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.outputTableFourDto.grant
                          ? row.outputTableFourDto.grant.title
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.outputTableFourDto.startDate
                          ? `${
                              row.outputTableFourDto.startDate
                                ? row.outputTableFourDto.startDate
                                : ""
                            } - ${
                              row.outputTableFourDto.endDate
                                ? row.outputTableFourDto.endDate
                                : ""
                            }`
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align={"right"}>
                        {row.outputTableFourDto.currentYearCost
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumSignificantDigits: 9,
                            }).format(row.outputTableFourDto.currentYearCost)
                          : ""}
                      </MuiTableCell>
                    </MuiTableRow>
                  ) : (
                    <MuiTableRow key={i}>
                      <MuiTableCell colSpan={6}>
                        {row.outputTableFourDto.facultyMember
                          ? row.outputTableFourDto.facultyMember.fullName
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align={"right"}>
                        {row.outputTableFourDto.currentYearCost
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumSignificantDigits: 9,
                            }).format(row.outputTableFourDto.currentYearCost)
                          : 0}
                      </MuiTableCell>
                    </MuiTableRow>
                  );
                })}
              {!loading &&
                (!outputTable4Data || outputTable4Data?.length === 0) && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="12">
                      {t("output.mainView.list.noData")}
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              {loading && (
                <MuiTableRow>
                  <MuiTableCell colSpan="7">
                    <MuiTypography
                      variant="h6"
                      color="textSecondary"
                      align="center"
                    >
                      {loading
                        ? t("globals.list.messages.fetchingInfo")
                        : t("applicants.mainView.list.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
          {loading && (
            <MuiBox sx={{ width: "100%" }}>
              <MuiLinearProgress />
            </MuiBox>
          )}
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};
