import axios from "axios";

// API call to get all submissions for copy submission dialog
export const getAllSubmissions = async (
  setAlert,
  clearAlert,
  setSubmissionsList,
  setLoadingSubmissionList
) => {
  setLoadingSubmissionList(true);
  clearAlert();
  try {
    const response = await axios({
      method: "get",
      url: `/submissions`,
      params: {
        ps: 500,
      },
    });
    if (response.data.count > 0 && response.data.values) {
      const values = response.data.values;
      // Marshalling the values for Autocomplete options
      const marshalledValues = values.map((sub, index) => {
        return {
          id: sub._links.self.href.split("/").pop(),
          name: sub.name,
          month: sub.month,
          year: sub.year,
          monthYear: sub.month && sub.year ? `${sub.month}/${sub.year}` : "",
          spo: sub.grant?.spoNumber || "",
          award: sub.grant?.grantNumber || "",
          title: sub.grant?.title || "",
          displayText: sub.grant?.spoNumber
            ? `${sub.name}, SPO# ${sub.grant.spoNumber}`
            : `${sub.name}`,
        };
      });
      setSubmissionsList(marshalledValues);
    } else {
      setSubmissionsList([]);
    }
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoadingSubmissionList(false);
  }
};

// API call to copy submission
export const copySubmission = async (
  t,
  setAlert,
  clearAlert,
  sourceSubValue,
  destinationSubValue
) => {
  clearAlert();
  try {
    const sourceSubId = sourceSubValue.id;
    const destinationSubId = destinationSubValue.id;
    await axios({
      method: "patch",
      url: `/submissions/${destinationSubId}`,
      data: {
        sourceSubmissionId: sourceSubId,
      },
    });
    setAlert(
      "success",
      t("submission.copy.notification.success", {
        sourceSubName: `"${sourceSubValue.name}"`,
        destinationSubName: `"${destinationSubValue.name}"`,
      })
    );
  } catch (error) {
    console.log("ERROR", error);
    setAlert(
      "error",
      t("submission.copy.notification.error", {
        sourceSubName: `"${sourceSubValue.name}"`,
        destinationSubName: `"${destinationSubValue.name}"`,
      })
    );
  }
};

export const getSubmission = async (
  id,
  setHasTabAccess,
  setLoading,
  setAlert,
  clearAlert,
  tab,
  getSelectedSubmissionName,
  t
) => {
  if (!id) {
    return;
  }

  try {
    setLoading(true);
    clearAlert();
    const response = await axios(`/submissions/${id}`);
    const addSubmissionLink = `addSubmission${tab}`;
    if (response) {
      if (tab === "Faculty") {
        const removeSubmissionLink = `removeSubmission${tab}`;
        setHasTabAccess({
          add: response.data._links[addSubmissionLink] ? true : false,
          remove: response.data._links[removeSubmissionLink] ? true : false,
        });
      } else {
        setHasTabAccess(response.data._links[addSubmissionLink] ? true : false);
      }
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * Returns Pre-fetched filters values for the Manage Submissions
 **/
export const getSubmissionsFiltersOptionValues = async (
  setSubmissionYearList,
  setSubmissionAdminList,
  setSubmissionObserverList,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();

    const request = {
      url: `/submissions/filters`,
    };
    const response = await axios(request);

    const { submissionAdmins, observers, submissionYears } = response.data;

    const convertNumberToString = submissionYears?.values.map(String);
    setSubmissionYearList(convertNumberToString ?? []);

    setSubmissionAdminList(submissionAdmins?.values ?? []);
    setSubmissionObserverList(observers?.values ?? []);
  } catch (error) {
    setAlert("error", error.message);
  }
};

/**
 ** Returns Submission List for Manage Submissions
 **/
export const getManageSubmissionsList = async (
  setTableData,
  setTotalCount,
  setLoading,
  setAlert,
  clearAlert,
  submissionPreference
) => {
  const { filters, pagination, sort } = submissionPreference.manageSubmissions;
  const { name, year, submissionAdmin, observer } = filters;
  const { page, pageSize } = pagination;
  const { orderBy, order } = sort;

  try {
    clearAlert();
    setLoading(true);

    const request = {
      url: `/submissions`,
      params: {
        name: name?.value?.name || "",
        year: year,
        submissionAdmin: submissionAdmin?.uid ?? "",
        observer: observer?.uid ?? "",
        p: parseInt(page) + 1,
        ps: pageSize || 10,
        s: `${
          orderBy === "year"
            ? `year:${order},month:${order},name:asc`
            : `name:${order ? `${order}` : `asc`}`
        }`,
      },
    };

    // Remove Blank Keys from Request Object
    Object.keys(request.params).forEach(
      (key) => !request.params[key] && delete request.params[key]
    );

    const response = await axios(request);

    if (response.data && response.data.values && response.data.count > 0) {
      setTableData(response.data.values);
      setTotalCount(response.data.totalCount);
    } else {
      setTableData([]);
      setTotalCount(0);
    }
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * Delete Submission
 *
 * @param {string} submissionId - Selected Submission to save faculty
 */
export const deleteSubmissionById = async (
  submissionId,
  setSnackBar,
  submissionPreference,
  setSubmissionPreference,
  setLoading,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    setLoading(true);
    const deleteFacultyResponse = await axios({
      method: "DELETE",
      url: `/submissions/${submissionId}`,
    });
    setLoading(false);

    if (deleteFacultyResponse.status === 204) {
      setSnackBar(true);

      setSubmissionPreference({
        ...submissionPreference,
        manageSubmissions: {
          ...submissionPreference.manageSubmissions,
          delete: submissionPreference.manageSubmissions.delete + 1,
        },
      });
      const currentCookie = document.cookie
        .split(";")
        .filter((s) => s.includes("submission"))[0]
        .replace(" submission=", "");
      if (currentCookie === submissionId) {
        const request = {
          url: `/submissions`,
          params: {
            vw: "brief",
          },
        };
        const response = await axios(request);
        if (response) {
          const validSubmissionId = response.data.values[0]._links.self.href
            .split("/")
            .pop();
          document.cookie = `submission=${validSubmissionId}`;
        }
      }
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};
