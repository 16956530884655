import axios from "axios";
/**
 * API call to get table 2 data
 */
export const getOutputTable2 = async (
  submissionId,
  setOutputData,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  try {
    clearAlert();
    setLoading(true);

    if (submissionId) {
      const request = {
        url: `/submissions/${submissionId}/outputs/table-two`,
      };
      const response = await axios(request);
      if (response.data.count > 0) {
        setOutputData(response.data.values);
      } else {
        setOutputData([]);
      }
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API call to get submission List
 * @param {*} setSubmissionList
 */
export const getOutputSubmissionsList = async (
  setSubmissionList,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: `/submissions`,
      params: {
        vw: "brief",
        ps: 500,
      },
    };
    const response = await axios(request);
    setSubmissionList(response.data.values);
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

/**
 *
 * @param {*} submissionsId
 * @param {*} setData
 */
export const getOutputTable3Data = async (
  submissionsId,
  setData,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  try {
    clearAlert();
    setLoading(true);

    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/table-three`,
      };
      const response = await axios(request);
      if (response.data.count > 0) {
        setData(response.data.values);
      } else {
        setData([]);
      }
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API Call to get OutputTable4 Data.
 */
export const getOutputTable4 = async (
  submissionId,
  setOutputTable4Data,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  try {
    clearAlert();
    setLoading(true);
    if (submissionId) {
      const request = {
        url: `/submissions/${submissionId}/outputs/table-four`,
      };
      const response = await axios(request);
      if (response.data.count > 0) {
        setOutputTable4Data(response.data.values);
      } else {
        setOutputTable4Data([]);
      }
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      (error.response?.data?.detail?.properties[0]?.resource === "Submission" ||
        error.response?.data?.detail?.properties[0]?.resource ===
          "SubmissionFaculty.Grants")
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 *
 * @param {univId} univId
 * @param {type} type
 * API call for table 2 popup
 */
export const getOutputTable2Popup = async (
  submissionsId,
  univId,
  type,
  setPopupData,
  setAlert,
  clearAlert,
  setLoading
) => {
  try {
    clearAlert();
    setLoading(true);
    if (submissionsId && univId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/table-two/trainees?facultyUniversityId=${univId}&filter=${type}`,
      };
      const response = await axios(request);

      setPopupData(
        response.data.values?.map((record) => {
          return {
            "#": record["traineeDto"]["rowId"],
            name: record["traineeDto"]["fullName"],
            sunetId: record["traineeDto"]["sunetId"] ?? "",
          };
        }) ?? []
      );
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * API Call to get Table1 Data.
 *
 * @param type
 *   PRE-DOC | POST-DOC
 */
export const getOutputTable1 = async (
  submissionsId,
  type,
  setTable1Data,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  try {
    setLoading(true);
    clearAlert();

    let response = "";
    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/table-one?type=${type}`,
      };
      response = await axios(request);
    }
    if (response) {
      setTable1Data(response.data.values);
    } else {
      setTable1Data([]);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 *
 * @param departmentId
 *  department id
 * @param outputTableOneEnumFilter
 *  Table One Enum Filter
 * @param traineeType
 *  PRE-DOC | POST-DOC
 */
export const getOutputTable1PopUp = async (
  submissionId,
  departmentId,
  outputTableOneEnumFilter,
  traineeType,
  setPopupData,
  setAlert,
  clearAlert,
  setLoading
) => {
  try {
    // clearAlert();
    setLoading(true);
    const MapEnumValueArr = {
      totalFaculty: "TOTAL_FACULTY",
      participatingFaculty: "PARTICIPATING_FACULTY",
      totalDoctorates: "TOTAL_DOCTORATES",
      totalDoctoratesSupportByHhsAward: "TOTAL_DOCTORATES_SUPPORTED_BY_HHS",
      totalDoctoratesWithParticipatingFaculty:
        "TOTAL_DOCTORATES_WITH_PARTICIPATING_FACULTY",
      eligibleWithParticipatingFaculty:
        "ELIGIBLE_DOCTORATES_WITH_PARTICIPATING_FACULTY",
      supportByTrainingGrantTge:
        "TGE_DOCTORATES_SUPPORTED_BY_THIS_TRAING_GRANT",
      supportByTrainingGrantRninezero:
        "R90_DOCTORATES_SUPPORTED_BY_THIS_TRAINING_GRANT",
    };
    const mappedEnumValue = MapEnumValueArr[outputTableOneEnumFilter];
    if (submissionId != null && departmentId) {
      const request = {
        url: `/submissions/${submissionId}/outputs/table-one/trainees?departmentCode=${departmentId}&outputTableOneEnumFilter=${mappedEnumValue}&traineeType=${traineeType}`,
      };
      const response = await axios(request);
      if (response) {
        setPopupData(response.data.values);
      } else {
        setPopupData([]);
      }
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * Popup API Call to get table 6A Data.
 *
 * @param year
 *  Year.
 * @param outputTableOneEnumFilter
 *  Table One Enum Filter.
 * @param departmentCode
 *  Department code.
 */
export const getOutputTable6APopUp = async (
  submissionId,
  year,
  outputTableOneEnumFilter,
  departmentCode,
  setPopupData,
  setAlert,
  clearAlert,
  setLoading
) => {
  try {
    // clearAlert();
    setLoading(true);
    const MapEnumValueArr = {
      totalApplicantPool: "TOTAL_APPLICANT_POOL",
      applicantsEligibleForSupport: "APPLICANTS_ELIGIBLE_FOR_SUPPORT",
      newEntrantsToProgram: "NEW_ENTRANTS_TO_PROGRAM",
      newEntrantsEligibleForSupport: "NEW_ENTRANTS_ELIGIBLE_FOR_SUPPORT",
      newEntrantsAppointed: "NEW_ENTRANTS_APPOINTED",
    };
    const mappedEnumValue = MapEnumValueArr[outputTableOneEnumFilter];
    if (submissionId != null && year) {
      const request = {
        url: `/submissions/${submissionId}/outputs/table-six-a/applicants?departmentCode=${departmentCode}&outputTableSixHeaderFilter=${mappedEnumValue}&year=${year}`,
      };
      const response = await axios(request);
      setPopupData(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * API Call to get Table6A Data.
 * @param type
 *   table-six-a-characteristics | table-six-a-count
 */
export const getOutputTable6A = async (
  submissionsId,
  type,
  setTable6AData,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  try {
    clearAlert();
    setLoading(true);

    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/${type}`,
      };
      const response = await axios(request);

      if (response.data.content) {
        setTable6AData(response.data);
      } else {
        setTable6AData([]);
      }
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API Call to get Table6B Data.
 * @param type
 *   table-six-b-characteristics | table-six-b-count
 */
export const getOutputTable6B = async (
  submissionsId,
  type,
  setTable6BData,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  try {
    clearAlert();
    setLoading(true);

    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/${type}`,
      };
      const response = await axios(request);

      if (response.data.content) {
        setTable6BData(response.data);
      } else {
        setTable6BData([]);
      }
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};
/**
 * Popup API Call to get table 6B Data.
 *
 * @param year
 *  Year.
 * @param outputTableOneEnumFilter
 *  Table One Enum Filter.
 * @param departmentCode
 *  Department code.
 */
export const getOutputTable6BPopUp = async (
  submissionId,
  year,
  outputTableSixBEnumFilter,
  degreeCode,
  setPopupData,
  setAlert,
  clearAlert,
  setLoading
) => {
  try {
    // clearAlert();
    setLoading(true);
    const MapEnumValueArr = {
      totalApplicantPool: "TOTAL_APPLICANT_POOL",
      applicantsEligibleForSupport: "APPLICANTS_ELIGIBLE_FOR_SUPPORT",
      newEntrantsToProgram: "NEW_ENTRANTS_TO_PROGRAM",
      newEntrantsEligibleForSupport: "NEW_ENTRANTS_ELIGIBLE_FOR_SUPPORT",
      newEntrantsAppointed: "NEW_ENTRANTS_APPOINTED",
    };
    const mappedEnumValue = MapEnumValueArr[outputTableSixBEnumFilter];
    const MapEnumDegreeArr = {
      PhDs: "PHD",
      MDs: "MD",
      "Dual-Degree Holders": "DUAL_DEGREE",
      "Other Degree Holders": "OTHERS",
    };
    const mappedDegreeValue = MapEnumDegreeArr[degreeCode];
    if (submissionId && year) {
      const request = {
        url: `/submissions/${submissionId}/outputs/table-six-b/applicants?degreeCode=${mappedDegreeValue}&outputTableSixHeaderFilter=${mappedEnumValue}&year=${year}`,
      };
      const response = await axios(request);
      setPopupData(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * API Call to get Table8A Data.
 */
export const getOutputTable8A = async (
  submissionsId,
  setTable8Data,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  setLoading(true);

  try {
    clearAlert();

    let response = "";
    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/table-eight-a`,
      };
      response = await axios(request);
    }

    if (response.data) {
      setTable8Data(
        response.data.map((outcome) => ({
          submissionOutcomeId: outcome.submissionOutcomeId,
          traineeName: outcome.traineeName,
          facultyMembers: outcome.facultyMembers || [],
          startDate: outcome.startDate || "",
          trainingSupportYears: outcome.trainingSupportYears || [],
          terminalDegrees: outcome.terminalDegrees || [],
          researchTopic: outcome.researchTopic || "",
          initialPositionSummary: outcome.initialPositionSummary || [],
          currentPositionSummary: outcome.currentPositionSummary || [],
          subsequentGrants: outcome.subsequentGrants || [],
        }))
      );
    } else {
      setTable8Data([]);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API Call to get Table8B Data.
 */
export const getOutputTable8B = async (
  submissionsId,
  setTable8Data,
  setAlert,
  clearAlert,
  setLoading,
  getSelectedSubmissionName,
  t
) => {
  setLoading(true);

  try {
    clearAlert();

    let response = "";
    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/table-eight-b`,
      };
      response = await axios(request);
    }

    if (response.data) {
      setTable8Data(
        response.data.map((outcome) => ({
          submissionOutcomeId: outcome.submissionOutcomeId,
          traineeName: outcome.traineeName,
          doctoralDegrees: outcome.doctoralDegrees || [],
          facultyMembers: outcome.facultyMembers || [],
          startDate: outcome.startDate || "",
          trainingSupportYears: outcome.trainingSupportYears || [],
          resultingDegrees: outcome.resultingDegrees || [],
          researchTopic: outcome.researchTopic || "",
          initialPositionSummary: outcome.initialPositionSummary || [],
          currentPositionSummary: outcome.currentPositionSummary || [],
          subsequentGrants: outcome.subsequentGrants || [],
        }))
      );
    } else {
      setTable8Data([]);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API call to Export XLSX
 * @param {*} tableName
 * @param {*} tableEndPoint
 * @param {*} submissionsId
 * @param {*} type
 */
export const getExportData = async (
  tableName,
  tableEndPoint,
  submissionsId,
  setAlert,
  clearAlert,
  setExportLoading,
  type
) => {
  setExportLoading(true);
  try {
    clearAlert();

    let response = "";
    if (submissionsId) {
      const request = {
        url: `/submissions/${submissionsId}/outputs/${tableEndPoint}`,
        params: { type },
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel",
          "Content-type": "application/vnd.ms-excel",
        },
      };
      response = await axios(request);
    }
    if (response.data) {
      const currentDate = new Date();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const date = ("0" + currentDate.getDate()).slice(-2);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Export_Output_${tableName}_${currentDate.getFullYear()}${month}${date}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setExportLoading(false);
  }
};
