import { useEffect, useState } from "react";
import {
  Grid2 as MuiGrid,
  Dialog as MuiDialog,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from "@mui/material";
import { Help as MuiHelpIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAlerts } from "common";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { SourceSubmissionTable } from "./SourceSubmissionTable";
import { DestinationSubmissionTable } from "./DestinationSubmissionTable";
import { copySubmission } from "app/services/submissionService";
import { Autocomplete } from "app/shared/ui/Autocomplete/Autocomplete";

// Copy Administrative Changes Dialog
export const CopySubmissionDialog = ({
  open,
  onClose,
  loading,
  submissionsList,
}) => {
  const { t } = useTranslation();
  const { clearAlert, setAlert } = useAlerts();

  // State variables
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [sourceSubValue, setSourceSubValue] = useState(null);
  const [sourceSubInputValue, setSourceSubInputValue] = useState("");

  const [destinationSubOptions, setDestinationSubOptions] = useState([]);
  const [destinationSubValue, setDestinationSubValue] = useState(null);
  const [destinationSubInputValue, setDestinationSubInputValue] = useState("");

  // Function to reset the values on close
  const resetValues = () => {
    setSourceSubValue(null);
    setSourceSubInputValue("");
    setDestinationSubValue(null);
    setDestinationSubInputValue("");
    setDestinationSubOptions([]);
  };
  // Function to handle the Yes button on confirmation dialog
  const handleConfirmationOk = () => {
    copySubmission(
      t,
      setAlert,
      clearAlert,
      sourceSubValue,
      destinationSubValue
    );
    setOpenConfirmation(false);
    resetValues();
    onClose();
  };
  // Function to handle the No button on confirmation dialog
  const handleConfirmationCancel = () => {
    setOpenConfirmation(false);
  };

  // useEffect to update the destination submission options based on the selected source submission
  useEffect(() => {
    if (sourceSubValue !== null) {
      const newDestinationSubOptions = submissionsList.filter(
        (sub) => sub.id !== sourceSubValue.id
      );
      setDestinationSubOptions(newDestinationSubOptions);
    } else {
      setDestinationSubOptions([]);
    }
  }, [submissionsList, sourceSubValue]);

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "1048px",
          maxWidth: "100%",
        },
      }}
    >
      <MuiDialogTitle>
        <MuiGrid container justifyContent="space-between">
          <MuiTypography variant="h2">
            {t("submission.copy.title")}
          </MuiTypography>
          <MuiTooltip title="TODO: Add help text">
            <MuiHelpIcon fontSize="small" />
          </MuiTooltip>
        </MuiGrid>
      </MuiDialogTitle>

      <MuiDialogContent>
        <MuiGrid container direction="column" gap="24px">
          <MuiGrid container gap="12px" paddingTop="8px">
            <Autocomplete
              fullWidth
              clientSide
              autoHighlight
              size="medium"
              noOptionsText={t("globals.list.messages.noOptions")}
              label={t("submission.copy.labels.sourceSubmission")}
              clientSideLoading={loading}
              value={sourceSubValue}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.displayText}
              onChange={(event, newValue) => {
                setSourceSubValue(newValue);
                setDestinationSubValue(null);
                setDestinationSubInputValue("");
              }}
              inputValue={sourceSubInputValue}
              onInputChange={(event, newInputValue) =>
                setSourceSubInputValue(newInputValue)
              }
              options={submissionsList}
            />
            <SourceSubmissionTable sourceSubValue={sourceSubValue} />
          </MuiGrid>

          <MuiGrid container direction="column" gap="12px">
            <Autocomplete
              fullWidth
              clientSide
              autoHighlight
              size="medium"
              noOptionsText={t("globals.list.messages.noOptions")}
              label={t("submission.copy.labels.destinationSubmission")}
              disabled={!sourceSubValue}
              options={destinationSubOptions}
              value={destinationSubValue}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.displayText}
              onChange={(event, newValue) => setDestinationSubValue(newValue)}
              inputValue={destinationSubInputValue}
              onInputChange={(event, newInputValue) =>
                setDestinationSubInputValue(newInputValue)
              }
            />
            <DestinationSubmissionTable
              destinationSubValue={destinationSubValue}
            />
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>

      <MuiDialogActions sx={{ padding: "0px 24px 16px 24px" }}>
        <MuiButton
          variant="outlined"
          color="primary"
          onClick={() => {
            onClose();
            resetValues();
          }}
          sx={{ minWidth: "100px" }}
        >
          {t("globals.form.actionButtons.cancel")}
        </MuiButton>
        <MuiButton
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenConfirmation(true);
          }}
          sx={{ minWidth: "100px" }}
          disabled={!sourceSubValue || !destinationSubValue}
        >
          {t("globals.form.actionButtons.copy")}
        </MuiButton>
      </MuiDialogActions>
      <ConfirmationDialog
        open={openConfirmation}
        message={t("submission.copy.confirmationMessage")}
        okLabel={t("globals.form.actionButtons.yes")}
        handleOk={handleConfirmationOk}
        cancelLabel={t("globals.form.actionButtons.no")}
        handleCancel={handleConfirmationCancel}
      />
    </MuiDialog>
  );
};
