import { Theme } from "common";

export const outputTableStyles = {
  appBarStyle: {
    backgroundColor: "#F4F4F4",
    "& .MuiTab-wrapper": {
      letterSpacing: 2,
    },
    "& .MuiTab-root": {
      fontWeight: Theme.typography.fontWeightBold,
      color: "rgba(0, 0, 0,0.54)",
      fontSize: 14,
    },
    "& .Mui-selected": {
      color: "#007C92",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#007C92",
    },
  },
  container: {
    "&.MuiTableContainer-root": {
      width: "100%",
      margin: 0,
      overflow: "visible",
    },
    "& .MuiTableCell-root": {
      padding: "6px 20px",
    },
    "& *": {
      fontSize: 14,
    },
  },
  blueHeader: {
    marginTop: "16px",
    "& .MuiTableCell-head": {
      backgroundColor: Theme.palette.primary.light,
      color: Theme.palette.text.primary,
      fontWeight: 700,
    },
  },
  fixHeader: {
    overflowX: "inherit",
  },
  actionButton: {
    "& button": {
      height: "40px",
    },
  },
  unorderedList: {
    listStyleType: "none",
    paddingInlineStart: "unset",
    marginBlockStart: "unset",
    marginBlockEnd: "unset",
  },
  noBold: {
    marginTop: 15,
    backgroundColor: Theme.palette.background.paper,
    "& .table1": {
      minWidth: 650,
      "& thead tr th:first-child": {
        minWidth: 305,
      },
    },
    "& .MuiTypography-root": {
      whiteSpace: "pre-line",
    },
  },
};
