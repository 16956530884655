import merge from "lodash/merge";
import { Theme } from "common";

/**
 * Training Grant specific style overrides to the AS-Common Theme definitions
 */
const STANFORD_TEXT_GRAY = "#333333";
const themeOverrides = {
  palette: {
    secondary: {
      main: STANFORD_TEXT_GRAY,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: { fontSize: "14px" },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {},
      },
    },
  },
};

// lodash.merge to insert our overrides
export const appTheme = merge(Theme, themeOverrides);
