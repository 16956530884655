import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TablePagination as MuiTablePagination,
  Typography as MuiTypography,
  Paper as MuiPaper,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Link as MuiLink,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@mui/material";
import {
  RemoveCircle as MuiRemoveIcon,
  Edit as MuiEditIcon,
  KeyboardArrowDown as MuiKeyboardArrowDownIcon,
  KeyboardArrowUp as MuiKeyboardArrowUpIcon,
} from "@mui/icons-material";
import { format, parseISO } from "date-fns";
/** Custom Components */
import { AuditInfo } from "app/shared/ui/AuditInfo/AuditInfo";
import CollapseTable from "app/shared/ui/CollapseTable/CollapseTable";
import FacultyDetailsTableHead from "./FacultyDetailsTableHeader";
import { FacultyTrainingRoles } from "app/shared/constants";
/** Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useFacultyDemographicsContext } from "app/services/facultyDemographicsContext";
import { getFacultyDemographicsDetails } from "app/services/facultyService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { facultyDetailsStyles } from "./FacultyDetails.styles";
// ************************** Faculty Details **************************
export const FacultyDetails = (props) => {
  const { t } = useTranslation();
  const { setMetaData } = useSelectedSubmission();

  // Context Object
  const { facultyPreference, setFacultyPreference } =
    useFacultyDemographicsContext();

  // Pagination
  const pageSize = useMemo(
    () => facultyPreference.facultyDemographics.pagination.pageSize || 10,
    [facultyPreference]
  );
  const page = useMemo(
    () => facultyPreference.facultyDemographics.pagination.page || 0,
    [facultyPreference]
  );

  const [facultyToUpdate, setFacultyToUpdate] = useState({
    faculty: [],
    timeout: null,
  });

  // Sorting
  const order = useMemo(
    () => facultyPreference.facultyDemographics.sort.order || "asc",
    [facultyPreference]
  );
  const orderBy = useMemo(
    () => facultyPreference.facultyDemographics.sort.orderBy || "fullName",
    [facultyPreference]
  );

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const selectedSubmission = getSelectedSubmission();

  const { setAlert, clearAlert } = useAlerts();

  const { navigate, loading, setLoading, data, setData, summary, setSummary } =
    props;

  const stringifiedFacultyPreference = JSON.stringify(facultyPreference);

  useEffect(() => {
    getFacultyDemographicsDetails(
      setData,
      facultyToUpdate,
      setFacultyToUpdate,
      setSummary,
      setLoading,
      selectedSubmission,
      facultyPreference,
      navigate,
      setAlert,
      clearAlert,
      setMetaData,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    selectedSubmission,
    orderBy,
    order,
    stringifiedFacultyPreference,
    navigate,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";

    setFacultyPreference({
      ...facultyPreference,
      facultyDemographics: {
        ...facultyPreference.facultyDemographics,
        sort: {
          orderBy: property,
          order: isAsc,
        },
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setFacultyPreference({
      ...facultyPreference,
      facultyDemographics: {
        ...facultyPreference.facultyDemographics,
        pagination: {
          ...facultyPreference.facultyDemographics.pagination,
          page: newPage,
        },
      },
    });
  };

  const handleChangePageSize = (event) => {
    let pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      summary.totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }

    setFacultyPreference({
      ...facultyPreference,
      facultyDemographics: {
        ...facultyPreference.facultyDemographics,
        pagination: {
          ...facultyPreference.facultyDemographics.pagination,
          page: pageValue,
          pageSize: parseInt(event.target.value, 10),
        },
      },
    });
  };

  return (
    <div>
      <MuiTableContainer
        component={MuiPaper}
        sx={facultyDetailsStyles.tableContainerRoot}
      >
        <MuiTable
          aria-label="customized table"
          stickyHeader
          sx={{
            tableLayout: "fixed",
            "& .MuiTableCell-root": {
              wordWrap: "break-word",
            },
          }}
        >
          <FacultyDetailsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <MuiTableBody>
            {loading && (
              <MuiTableRow>
                <MuiTableCell colSpan="10">
                  <MuiTypography
                    variant="h6"
                    color="inherit"
                    align="center"
                    paragraph={false}
                  >
                    {t("globals.list.messages.fetchingInfo")}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            )}
            {!loading && data?.length === 0 && (
              <MuiTableRow>
                <MuiTableCell colSpan="11">
                  <MuiTypography variant="body2" color="inherit" align="center">
                    {t("faculty.mainView.list.noData")}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            )}
            {!loading &&
              data?.length > 0 &&
              data.map((row) => (
                <CollapsibleTable
                  facultyTableRow={row}
                  props={props}
                  key={row.universityId}
                />
              ))}
          </MuiTableBody>
        </MuiTable>
        {loading && (
          <MuiBox sx={{ width: "100%" }}>
            <MuiLinearProgress />
          </MuiBox>
        )}
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={parseInt(summary.totalCount ?? 0)}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
      </MuiTableContainer>
    </div>
  );
};

function CollapsibleTable({ facultyTableRow, props }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const collapsibleTableHeader = {
    spo: "SPO #",
    title: "Title",
    awardNumber: "Award Number",
    projectPeriod: "Project Period",
    fundingSource: "Funding Source",
    countOfiIMpi: "Count of PI/MPI",
    currerntYearDirectCost: "Current Year Direct Cost",
    currerntYearDirectCostPerPiMpi: "Current Year Direct Cost per PI/MPI",
  };

  let facultyCollapseTableBody = [];
  facultyTableRow.grants &&
    facultyTableRow.grants.map((response) => {
      let dataObject = {};
      dataObject.spoNumber = response.spoNumber;
      dataObject.title = response.title;
      dataObject.grantNumber = response.grantNumber;
      dataObject.projectPeriod = `${
        response.startDate
          ? format(parseISO(response.startDate), "MM/yyyy")
          : ""
      } - ${
        response.endDate ? format(parseISO(response.endDate), "MM/yyyy") : ""
      }`;
      dataObject.fundingSource = response.fundingSource;
      dataObject.piCount = response.piCount;
      dataObject.currentYearCost = response.currentYearCost
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumSignificantDigits: 9,
          }).format(response.currentYearCost)
        : "";
      dataObject.currentYearCostPerPiMpi = response.currentYearCostPerPiMpi
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumSignificantDigits: 9,
          }).format(response.currentYearCostPerPiMpi)
        : "";

      return facultyCollapseTableBody.push(dataObject);
    });

  return (
    <>
      <MuiTableRow key={facultyTableRow.universityId}>
        <MuiTableCell>
          {facultyCollapseTableBody.length > 0 && (
            <MuiIconButton
              aria-label="expand row"
              size="small"
              sx={facultyDetailsStyles.collapseIcon}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <MuiKeyboardArrowUpIcon /> : <MuiKeyboardArrowDownIcon />}
            </MuiIconButton>
          )}
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.facultyFullName}
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.orcidId}{" "}
          <AuditInfo isEdited={facultyTableRow.orcidIdMod} />
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.commonsId}{" "}
          <AuditInfo isEdited={facultyTableRow.commonsIdMod} />
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.email}{" "}
          <AuditInfo isEdited={facultyTableRow.emailMod} />
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.degrees &&
            facultyTableRow.degrees
              .map((degree) => degree["shortName"])
              .join(", ")}{" "}
          {/*API Changes*/}
          <AuditInfo isEdited={facultyTableRow.submissionFacultyDegreesMod} />
        </MuiTableCell>
        <MuiTableCell align="left" sx={facultyDetailsStyles.wrapIcon}>
          {facultyTableRow.rank && facultyTableRow.rank.name}
          <AuditInfo isEdited={facultyTableRow.rankMod} />
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.department && facultyTableRow.department.name}
          {/*API Changes*/}
          <AuditInfo
            isEdited={facultyTableRow.submissionFacultyDepartmentsMod}
          />
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.researchInterest}{" "}
          <AuditInfo isEdited={facultyTableRow.researchInterestMod} />
        </MuiTableCell>
        <MuiTableCell align="left">
          {facultyTableRow.trainingRoles &&
          facultyTableRow.trainingRoles.length > 0
            ? facultyTableRow.trainingRoles
                .map((trainingRole) =>
                  t(FacultyTrainingRoles[trainingRole["role"]])
                )
                .join(", ")
            : null}{" "}
          <AuditInfo
            isEdited={facultyTableRow.submissionFacultyTrainingRolesMod}
          />
        </MuiTableCell>
        <MuiTableCell align="left" sx={facultyDetailsStyles.actionButton}>
          <span style={facultyDetailsStyles.actionContent}>
            <span
              style={
                !facultyTableRow._links.editDetails
                  ? facultyDetailsStyles.hideVisiblity
                  : {}
              }
            >
              {facultyTableRow._links.editDetails && (
                <MuiTooltip title={t("globals.list.actionIcons.edit.tooltip")}>
                  <MuiIconButton>
                    <RouterLink
                      to={`/faculty/${facultyTableRow._links.editDetails.href
                        .split("/")
                        .pop()}/edit`}
                    >
                      <MuiEditIcon fontSize="small" />
                    </RouterLink>
                  </MuiIconButton>
                </MuiTooltip>
              )}
            </span>
            <span
              style={
                !facultyTableRow._links.removeSubmissionFaculty
                  ? facultyDetailsStyles.hideVisiblity
                  : {}
              }
            >
              {facultyTableRow._links.removeSubmissionFaculty && (
                <MuiTooltip
                  title={t("globals.list.actionIcons.remove.tooltip")}
                >
                  <MuiIconButton
                    onClick={() =>
                      props.handleDelete(
                        "One",
                        new URL(
                          facultyTableRow._links.removeSubmissionFaculty.href
                        ),
                        facultyTableRow.facultyFullName
                      )
                    }
                  >
                    <MuiLink aria-label="delete">
                      <MuiRemoveIcon fontSize="small" />
                    </MuiLink>
                  </MuiIconButton>
                </MuiTooltip>
              )}
            </span>
          </span>
        </MuiTableCell>
      </MuiTableRow>
      <MuiTableRow sx={facultyDetailsStyles.collapseRowBackgroundColor}>
        <MuiTableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: "38px",
            paddingRight: "20px",
          }}
          colSpan={11}
        >
          <CollapseTable
            label="faculty"
            header={collapsibleTableHeader}
            body={facultyCollapseTableBody}
            open={open}
            tableClass={facultyDetailsStyles.collapseWidth}
          />
        </MuiTableCell>
      </MuiTableRow>
    </>
  );
}
