import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Grid2 as MuiGrid,
  Button as MuiButton,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { CloudDownload as MuiCloudDownloadIcon } from "@mui/icons-material";
/** Custom Components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Services */
import {
  getOutputTable3Data,
  getExportData,
  getOutputSubmissionsList,
} from "app/services/outputService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { outputTableStyles } from "./OutputTable3.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const Table3 = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [data, setData] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { handleSubmissionUpdate } = useSelectedSubmission();
  const selectedSubmission = getSelectedSubmission();

  const outputTable3Columns = "output.mainView.list.tableColumns";
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOutputTable3Data(
      selectedSubmission,
      setData,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [setData, selectedSubmission]);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    handleSubmissionUpdate();
  };

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      "Table_3",
      "table-three",
      getSelectedSubmission(),
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  return (
    <MuiGrid container direction="column">
      <MuiGrid sx={sharedStyles.outputAutoCompleteWrapper}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        container
        justifyContent="flex-end"
        sx={{ marginBottom: "16px" }}
      >
        <MuiButton
          variant="outlined"
          color="secondary"
          onClick={handleExport}
          disabled={exportLoading || !data.length}
        >
          {exportLoading && (
            <Spinner visible={true} style={sharedStyles.exportSpinnerStyles} />
          )}
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}{" "}
        </MuiButton>
      </MuiGrid>
      <MuiGrid container>
        <MuiTableContainer
          component={MuiPaper}
          sx={outputTableStyles.fixHeader}
        >
          <MuiTable aria-label="customized table" stickyHeader>
            <MuiTableHead sx={outputTableStyles.blueHeader}>
              <MuiTableRow>
                <MuiTableCell key="grantTitle" width="30%">
                  {t(`${outputTable3Columns}.grantTitle`)}
                </MuiTableCell>
                <MuiTableCell key="awardNumber">
                  {t(`${outputTable3Columns}.awardNumber`)}
                </MuiTableCell>
                <MuiTableCell key="projectPeriod">
                  {t(`${outputTable3Columns}.projectPeriod`)}
                </MuiTableCell>
                <MuiTableCell key="pd/pi">
                  {t(`${outputTable3Columns}.pd/pi`)}
                </MuiTableCell>
                <MuiTableCell key="preDoctoral">
                  {t(`${outputTable3Columns}.preDoctoral`)}
                </MuiTableCell>
                <MuiTableCell key="postDoctoral">
                  {t(`${outputTable3Columns}.postDoctoral`)}
                </MuiTableCell>
                <MuiTableCell key="shortTerm">
                  {t(`${outputTable3Columns}.shortTerm`)}
                </MuiTableCell>
                <MuiTableCell key="participatingFaculty">
                  {t(`${outputTable3Columns}.participatingFaculty`)}
                </MuiTableCell>
                <MuiTableCell key="overLappingFaculty">
                  {t(`${outputTable3Columns}.overLappingFaculty`)}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {!loading &&
                data?.map((row, index) => (
                  <MuiTableRow key={encodeURIComponent(index)}>
                    <MuiTableCell>{row.tableThreeDto.title ?? ""}</MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.grantNumber ?? ""}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.projectPeriod ?? ""}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.principalInvestigators
                        ? row.tableThreeDto.principalInvestigators
                            .map((investigatorName) => investigatorName)
                            .join("\n")
                        : ""}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.predocPositionsCount ?? 0}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.postdocPositionsCount ?? 0}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.numberOfShortTermPositions ?? 0}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.numberOfParticipatingFaculty
                        ? row.tableThreeDto.numberOfParticipatingFaculty.trim()
                        : ""}
                    </MuiTableCell>
                    <MuiTableCell sx={outputTableStyles.newLine}>
                      {row.tableThreeDto.namesOfOverlappingFaculty
                        ? row.tableThreeDto.namesOfOverlappingFaculty
                            .map((facultyName) => facultyName)
                            .join("\n")
                        : ""}
                    </MuiTableCell>
                  </MuiTableRow>
                ))}
              {!loading && (!data || data?.length === 0) && (
                <MuiTableRow>
                  <MuiTableCell colSpan="12">
                    <MuiTypography
                      variant="body2"
                      color="inherit"
                      align="center"
                    >
                      {t("output.mainView.list.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
              {loading && (
                <MuiTableRow>
                  <MuiTableCell colSpan="9">
                    <MuiTypography
                      variant="body2"
                      color="inherit"
                      align="center"
                    >
                      {loading
                        ? t("globals.list.messages.fetchingInfo")
                        : t("applicants.mainView.list.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
          {loading && (
            <MuiBox sx={{ width: "100%" }}>
              <MuiLinearProgress />
            </MuiBox>
          )}
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};
