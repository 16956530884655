import { Theme } from "common";

// Styles for disabled text fields
export const disabledTextFieldStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: Theme.palette.text.primary,
  },
  "& .MuiInput-underline.Mui-disabled:before": {
    borderBottomStyle: "solid",
  },
};

// Styles to keep the Table Header Label text white
export const whiteTableHeaderLabel = {
  "& .MuiTableSortLabel-root": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-root:hover": {
    color: "white",
  },
  "&.Mui-active": {
    color: "white",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
    alignSelf: "center",
  },
};

export const sharedStyles = {
  autocompleteWrapper: {
    width: "350px",
    "& .MuiFormControl-root": {
      width: "140%",
      marginTop: "10px",
      marginBottom: "5px",
    },
  },
  /** AutoComplete Submission dropdown for Output tab */
  outputAutoCompleteWrapper: {
    width: "30%",
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: "16px",
      marginBottom: "16px",
    },
  },
  dummyAutocompleteOption: {
    '.MuiAutocomplete-option[aria-disabled="true"] &': {
      color: "#000", // grey text is not legible with the opacity from MUI Autocomplete
    },
  },
  autocompleteRoot: {
    "& .MuiAutocomplete-popupIndicatorOpen": {
      // Stop MUI from rotating the icon by rotating it back
      rotate: "180deg",
    },
    "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
      // Move dropdown arrow and clear X up to horizontal center
      height: "auto",
    },
    "& .MuiInputLabel-root, .MuiInputBase-input": {
      fontSize: "14.5px",
    },
  },
  breadcrumb: {
    "& a": {
      textDecoration: "none",
    },
    "& a:visited": {
      color: "gray",
    },
  },
  exportSpinnerStyles: {
    scale: "50%",
    position: "absolute",
  },
};

export const sharedDatePickerStyle = {
  "& .MuiInputBase-root": {
    fontSize: "14px",
    paddingRight: "4px",
    "& .MuiInputBase-input": {
      padding: "12px",
      paddingRight: "0px",
    },
  },
  "& .MuiSvgIcon-root": {
    fill: Theme.palette.grey[400],
    fontSize: "21px",
  },
  "& .MuiIconButton-root": {
    margin: 0,
  },
  "& .MuiFormHelperText-root": {
    marginX: "3px",
    fontSize: "12px",
  },
};
