import { Theme } from "common";

export const outputTableStyles = {
  root: {
    margin: 3,
    backgroundColor: Theme.palette.background.paper,
    "& .MuiButton-root": {
      marginRight: 0,
      marginBottom: 15,
    },
  },
  tableOne: {
    minWidth: 650,
    "& thead tr th:first-of-type": {
      minWidth: 280,
      fontWeight: 700,
    },
    "& thead tr th:not(:first-of-type)": {
      textAlign: "right",
      fontWeight: 700,
    },
    "& tbody tr:last-child td:first-of-type": {
      fontWeight: 700,
    },
    "& tbody td:not(:first-of-type) ": {
      textAlign: "right",
    },
  },
  divStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiTableContainer-root": {
      boxShadow: "-1px 2px 3px 2px #c4c4c466",
      border: "1px solid #e0e0e0",
      overflowX: "inherit",
    },
  },
};
