import {
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";

export const Spinner = (props) => (
  <MuiBackdrop
    open={props.visible}
    sx={{ backgroundColor: "transparent", ...props.style }}
  >
    <MuiCircularProgress />
  </MuiBackdrop>
);
